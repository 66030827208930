.main-title {
  font-variation-settings: "wght" 300;
  color: #323232;
  font-size: 20px;
  margin: 0;
  margin-left: 20px;
  margin-bottom: 14px;
  /* text-shadow: 0px 4px 4px rgb(0, 0, 0, 0.25); */
}

.title-color {
  margin-left: 5px;
  color: #0076ee;
  white-space: pre;
  /* font-weight: 400; */
  font-variation-settings: "wght" 700;
}

.top-area {
  width: 100%;
  background-color: #131718;
}
