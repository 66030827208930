.NoneFeed {
  width: auto;
  margin: 0px 27px;
  height: 380px;
  /* height: 100%; */
  background-color: #f1f7ff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .heart-icon {
    width: 70px;
    height: 70px;

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  p {
    margin: 0;
    padding: 0;
    font-size: 16px;
    color: gray;
  }
}
