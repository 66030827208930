h2 {
  padding: 20px;
  margin: 0;
}

.CategoryModal {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 100;
  height: 100%;
  width: 100%;
  background-color: transparent;
  opacity: 0;
  transform: translateY(100%);
  transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
  pointer-events: none;
}

.see {
  opacity: 1;
  transform: translateY(0);
  pointer-events: auto;
}

.modal-container {
  width: 100%;
  height: 100%;
  background-color: white;
  margin-top: 44px;
  border-radius: 15px;
  transition: transform 0.5s ease-in-out;
  transform: translateY(100%);
}

.on {
  transform: translateY(0);
}

.modal-title {
  font-size: 20px;
  font-weight: 700;
  margin-block: 22px 30px;
}
.top-section {
  display: flex;
  flex-flow: column;
  align-items: center;
  padding: 13px;
}

/* 게시판 */
.Board {
  width: 100%;
  /* background-color: gray; */
}

.Board_title {
  /* background-color: aqua; */
  margin: 0 auto;
  padding-left: 5px;
  padding-block: 5px;
  width: 85%;
  border-bottom: 1px solid #dbeafe;
  font-size: 17px;
  font-weight: 600;
}

.Board_content {
  /* all: unset; */
  margin-inline: 20px;
  padding-block: 35px 20px;

  list-style: none;

  li {
    margin-bottom: 20px;
    padding: 0;
    display: flex;
    justify-content: space-between;

    & input[type="radio"] {
      width: 24px;
      height: 24px;
      cursor: pointer;
    }
  }
}
.LinkBox_container {
  padding: 20px 30px;
  justify-content: space-around;
}
.LinkBox {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  border-radius: 5px;
  gap: 8px;
}

.LinkBox_img {
  margin-top: 3px;
  width: 92px;
  height: 92px;
  background-color: #dceef1;
  display: flex;
  justify-content: center;

  img {
    width: 80px;
    height: 80px;
  }
}
