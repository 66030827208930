.test_container {
  margin: 0 auto;

  max-width: 500px;
  /* min-height: 100vh; */
  height: 100dvh;
  /* background-color: #2c2c2c; */
  overflow: hidden;
  position: relative;
  overscroll-behavior: none;
}

.container {
  overflow: auto;
  scrollbar-width: none;
}

/* 왼쪽 컨텐츠 영역 */
.content-box {
  color: #ffffff;
  /* flex: 6; */
  width: 430px;
  /* min-height: 100%; */
  background-color: #2c2c2c;
}

.top_bar_area {
  margin-top: 10px;
  height: 50px;
  /* background-color: blue; */
  width: 90%;
  margin-bottom: 10px;
  display: flex;
  justify-content: start;
}

.top_bar_area p {
  margin: auto;
}

.content-container {
  /* background-color: blue; */
  /* border-bottom: 2px solid white; */
  margin: 0 auto;
  width: 90%;

  height: calc(100dvh - 100px);
  position: relative;
  /* margin-left: 20px; */
}

/* 글자수 세기 스타일 */
.count-text {
  color: #5e5e5e;
  font-size: 14px;
  width: 100%;
  text-align: right;
  display: inline-block;
}
/* 해시태그 본문 스타일 */
.input-area {
  background-color: #000000;
  padding: 10px;
  margin-bottom: 30px;
  border: 1px solid white;
  border-radius: 5px;
}
/* 해시태그 영역 */
.boxbox {
  height: 150px;
}

.hash-tag-area {
  width: 100%;
  height: 40%;
  margin-bottom: 40px;
}

.hash-tag-area > #hashtag {
  color: white;
  font-size: 16px;
  margin-bottom: 5px;
}

.write-tag {
  background-color: #000000;
  color: #898989;
  width: 95%;
  height: 70%;
  margin-inline: 5px;
  border: none;
  border-bottom: 1px solid white;
}

/* 닉네임 및 날짜 */
.sup_info {
  margin-bottom: 10px;
  width: 100%;
  height: 10%;
}

.sup_info > .nick_name {
  margin: 0px;
  padding: 0px;
  /* margin-bottom: 7px; */
  height: 50%;
  display: flex;
  align-items: end;
  color: #ffffff;
}

.sup_info > .date {
  color: gray;
  display: flex;
  /* height: 50%; */
}

/* 해시태그 영역 */
.hashtag-box {
  height: 5%;
  width: 100%;
  /* display: flex; */
  align-items: center;
  margin-right: 5px;
  color: #7960ec;
  cursor: pointer;
}

/* 글 내용 영역 */
.feed-content {
  margin-top: 10px;
  width: 100%;
  height: 12%;
  font-weight: 400;
}

/* 이미지 보이는 곳 */
.write-image-box {
  height: 150px;
  margin-bottom: 10px;
  width: 100%;
  border: white dotted 1px;
  background-color: #000000;
  overflow-x: auto;
  scrollbar-width: none;
  display: flex;
  justify-content: start;
  align-items: start;
}

.upload_area > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* background-color: blue; */
  /* overflow-x: auto; */
  /* display: flex; */
}

#upload-file[type="file"] {
  display: none;
}

.upload_area {
  width: 100%;
  height: 100%;
  display: flex;
  /* justify-content: center;
  align-items: center; */
  cursor: pointer;
}

.upload-text {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  span {
    font-size: 30px;
    color: white;
  }
  div {
    color: white;
  }
}

/* 이미지 부분 */
.image-container {
  width: 100%;
  height: 25%;
  /* overflow: hidden; */
  /* display: flex; */
  /* flex-direction: row; */
  /* background-color: #98a0ff; */
  margin-top: 10px;
  margin-bottom: 10px;

  /* img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  } */
}

.image-box {
  margin-top: 10px;
  margin-bottom: 15px;
  height: 25%;
  width: 100%;
}

/* 기본 */
.image-origin {
  margin: 0 auto;
  width: 100%;
  height: 100%;
}

.image-origin > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* 2개 이상 이미지 */
.two-over-image {
  display: flex;
  gap: 10px;
  overflow-x: auto;
  scrollbar-width: none;
}

.two-over-image > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* 4개 이미지 */
.image-show {
  margin: 0 auto;
  width: 90%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(2, 49%);
  grid-template-rows: repeat(2, 49%);
  row-gap: 3px;
  column-gap: 3px;
  place-content: center;
}

.image-show > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.image-show > img:nth-of-type(1) {
  border-top-left-radius: 5px;
}

.image-show > img:nth-of-type(2) {
  border-top-right-radius: 5px;
}

.image-show > img:nth-of-type(3) {
  border-bottom-left-radius: 5px;
}

.image-show > img:nth-of-type(4) {
  border-bottom-right-radius: 5px;
}

/* 1개짜리 이미지 */
.one-image {
  display: flex;
}

.one-image > img {
  object-fit: contain;
}

/* 2개짜리 이미지 */
.two-image {
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(1, 100%);
  margin: 0 auto;
  width: 100%;
  height: 100%;
  display: grid;
  column-gap: 3px;
  place-content: center;
}

.two-image > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* 3개짜리 이미지 */
.three-image {
  grid-template-areas:
    "img1 img2"
    ".    img3";
  grid-template-columns: 1fr, 1fr;
  /* grid-template-rows: repeat(1, 50%); */
  /* row-gap: 3px; */
  /* column-gap: 3px; */
  place-content: center;
  /* background-color: blue; */
}

.three-image > img:nth-of-type(1) {
  grid-area: img1;
  grid-row-start: 1;
  grid-row-end: 3;
  background-color: orange;
  border-bottom-left-radius: 5px;
}

.three-image > img:nth-of-type(2) {
  grid-area: img2;
  background-color: green;
}

.three-image > img:nth-of-type(3) {
  grid-area: img3;
  background-color: red;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 5px;
}

/* 입력 페이지 */
.image-show > input[type="file"] {
  /* margin: 0 auto; */
  width: 100px;
  height: 100px;
  background-color: blue;
}

/* 각종 컨텐츠 */

.fclass-box {
  height: 30%;
  width: 100%;
  display: flex;
  align-items: center;
}

/* 1. 사지선다 */
.fclass-container {
  width: 100%;
  height: 100%;
  /* background-color: pink; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.fclass-container > input {
  text-align: center;
  border: 1px white solid;
  border-radius: 5px;
  color: white;
}

/* 2. 카드(공감)  */

.empathy-box {
  width: 120px;
  height: 70px;
  border: 1px gray solid;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  background-color: #000000;
  color: white;
}

/* .empathy-box::placeholder {
  color: white;
  font-size: 15px;
} */

/* 3. 이지선다 */
.balance-box {
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.sel-btn {
  width: 120px;
  height: 60px;
  border: 1px gray solid;
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  cursor: pointer;
}

/* 4. 외부 링크 (station)*/
.external-box {
  width: 85%;
  height: 60px;
  border: 1px solid gray;
}

.link-info-box {
  width: 90%;
  height: 100%;
  font-size: 12px;
  margin-left: 15px;
  padding-left: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  /* align-items: center; */
}

.link-info-box > h1,
h5 {
  padding: 0px;
  margin: 0px;
}

.link-info-box > h1 {
  font-size: 16px;
}

/* 댓글 영역 */
.comment-box {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  /* justify-content: end; */
  /* background-color: gray; */
  min-width: 100%;
  height: 12%;
  /* flex: 1; */
}

/* 상호작용 영역 */
.interaction-box {
  /* flex: 1; */
  width: 70px;
  min-height: 100%;
  /* background-color: blue; */
  background-color: #101010;
  color: #a19b8f;
}

.button-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

/* 이미지 크기 */
.btn-img {
  width: 25px;
  height: 25px;
  object-fit: cover;
  cursor: pointer;
  /* background-color: yellow; */
}

.comment-img {
  width: 30px;
  height: 30px;
  margin-bottom: -8px;
}

.star-img {
  /* filter: invert(53%) sepia(1%) saturate(0%) hue-rotate(213deg) brightness(94%) contrast(88%); */
  filter: invert(98%) sepia(98%) saturate(7495%) hue-rotate(356deg) brightness(102%) contrast(104%);
}

/* 버튼 공통 속성 */
.btn-box {
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

#text {
  margin-top: 5px;
  font-size: 11px;
}

/* 글쓰기 버튼 */
.write-box {
  flex-grow: 1.5;
  display: flex;
  justify-content: center;
}

/* 추천 안 함 버튼 */

.not-recommend-box {
  flex-grow: 0.5;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.not-recommend-btn {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* 상호작용 버튼 */
.action-box {
  flex-grow: 1;
  display: flex;
  justify-content: center;
}

.action-btn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 80%;
  gap: 20px;
}

.action-btn-each {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* 댓글 모달 창 */
.modal-container {
  position: absolute;
  z-index: 999;
  width: 100%;
  height: 80%;
  display: flex;
  justify-content: center;
  /* background-color: honeydew; */
}

.feed-comment-modal {
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  margin: 0 auto;
  bottom: 0;
  height: 700px;
  width: 90%;
}

.text-section {
  display: flex;
  justify-content: space-between;
  margin: 10px;
}

.text-box {
  display: flex;
  flex-direction: column;

  .text-1 {
    color: black;
    margin: 2px;
  }

  .text-2 {
    margin: 2px;
    color: white;
  }
}

.icon-modal {
  display: flex;
  justify-content: space-between;
  width: 130px;
  height: 50px;
  align-items: center;
}

.button-modal {
  border: 0;
  cursor: pointer;
  background-color: transparent;
  color: #cacaca;
  font-size: 12px;
}

.star-modal {
  text-align: center;

  .img-star {
    height: 15px;
    width: 15px;
    cursor: pointer;
  }

  p {
    margin: 0;
    font-size: 10px;
    color: black;
  }
}

.comment-modal {
  /* position: absolute; */
  /* justify-self: center; */
  /* top: 10vh; */
  width: 100%;
  /* height: calc(100vh-1vh); */
  background-color: #868686;
  padding: 5px;
  border-radius: 10px;
  padding-top: 5px;

  .top_bar {
    color: white;
    text-align: center;
    cursor: pointer;
    margin-left: auto;
    margin-right: 15px;
  }
}
.comment-more-see {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 10px 0;
  border-bottom: 1px solid #dbdbdb;
}
.comment-more-see nav:first-child {
  flex-grow: 1;
  text-align: center;
}
/* ////////////////////////// */
.write-container {
  margin: 0 auto;
  margin-top: 10px;
  /* margin-left: 25px; */
  width: 95%;
  height: 1000px;
  /* background-color: aqua; */
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  border: 1px solid #bebebe;
  padding: 10px;
  border-radius: 5px;
  hr {
    height: 3px;
    background-color: white;
    border: none;
    opacity: 1;
  }
}

.divide-line {
  width: 100%;
  height: 1px;
  background-color: gray;
}

/* ///////////////////////// */
.databox {
  /* background-color: purple; */
  width: 100%;
  position: relative;
  transition: transform 0.8s ease;
}

.movedown {
  transition: transform 0.5s ease;
}

.img-area {
  width: 100%;
  height: 450px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: blue; */
  /* z-index: 5; */
  position: absolute;
  /* float: left; */
  /* top: ; */
  /* top: 0px; */
  /* transition: transform 0.3s ease; */
}

.img-area img {
  width: 200px;
  height: 400px;
  z-index: 5;
}

.boxx {
  margin: 0;
  box-sizing: border-box;
  /* padding-top: 20px; */
  height: 400px;
  transition: height 0.5s ease, opacity 0.8s ease;
  opacity: 0;
}

.hidden {
  /* display: none; */
  height: 0px;
}

/* 피드 관련 스타일 */
.feed {
  margin: 0 auto;
  /* background-color: #ffffff; */
  background-color: #2c2c2c;
  /* border-radius: 25px; */
  width: 100%;
  /* height: 350px; */
  height: auto;
  color: black;
  margin-top: 6px;
  border-radius: 10px;
  position: relative;
  min-width: 500px;
}
@media (max-width: 500px) {
  .feed {
    min-width: 100%;
  }
}

.info_area {
  margin: 0 auto;
  width: 95%;
  /* border-bottom: 1px solid purple; */
}

.top_part {
  /* margin-right: -5px; */
  margin-left: 2px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.planet_name {
  display: flex;
  justify-content: center;
  align-items: center;
}

.planet_name img {
  width: 40px;
  height: 40px;
  margin-right: 7px;
}

.sup_people {
  margin-right: 20px;
  color: #787878;
}
.feed-text {
  height: 100px;
  margin-left: 20px;
  color: #ffffff;
}
.tag-text {
  /* height: 100px; */
  margin-left: 20px;
  color: #9f80ff;
  cursor: pointer;
}
.circle {
  margin-right: 5px;
  width: 15px;
  height: 15px;
  border-radius: 100%;
  /* background-color: ; */
}

.write-date {
  margin-left: 10px;
  font-size: 12px;
  color: gray;
}

.title {
  width: 100%;
  white-space: normal;
}

.link_box {
  text-decoration: underline;
  margin-left: 20px;
  background-color: #5f5f5f;
  color: #ffffff;
  width: 85%;
  height: auto;
  border-radius: 10px;
  /* color: black; */
  padding-bottom: 10px;
  border: black solid 1px;
  cursor: pointer;
}

.link_box h1 {
  margin: 0;
  margin-left: 10px;
  padding-top: 10px;
}

.link_box h5 {
  margin: 0;
  padding: 0;
  padding-top: 5px;
  margin-left: 10px;
}

.button_container {
  display: flex;
  justify-content: space-evenly;
  gap: 15px;
  width: 100%;
  align-items: center;
  min-height: 100px;
  /* background-color: yellow; */
}

.select_button {
  min-width: 150px;
  width: 100%;
  height: 80px;
  cursor: pointer;
  color: black;
  border: 1px solid #ababab;
  background-color: white;
  border-radius: 5px;
  /* box-shadow: 0px 2px 3px gray; */
}

.quiz_box {
  list-style-type: none;
  /* background-color: blue; */
  margin: 0 auto;
  width: 80%;
  /* height: 100%; */
  padding: 0;
  border-radius: 15px;
  font-size: 13px;
}

.quiz_box li {
  display: flex;
  cursor: pointer;
  justify-content: space-between;
  padding: 12px 20px;
  background-color: white;
  color: black;
  border: 1px #888888 solid;
}

/* 
.quiz_box li:first-child {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.quiz_box li:last-child {
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
} */

.quiz_select {
  background-color: #d2c8f7;
}

/* 피드 넘어가도록 하는 부분 */

.area {
  margin: 0 auto;
  height: 700px;
  width: 90%;
  transform: translateY(-200px);
  background-color: #7a7a7a;
  /* opacity: 0.; */
  position: relative;
  /* position: absolute; */
  border-radius: 10px;
  /* z-index: 1; */
  /* overflow: scroll; */
  /* scrollbar-width: none; */

  /* overflow: auto; */
}

.scroll-area {
  /* height: 1000px; */
  width: 150%;
  /* background-color: blue; */
  overflow: scroll;
  scrollbar-width: none;
}

/* 피드 작성 스타일 */

.input_feed {
  margin: 0 auto;
  background-color: #7a7a7a;
  border-radius: 25px;
  width: 450px;
  height: 400px;
  color: white;
  /* margin-top: 20px; */
  opacity: 1;
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.input_title {
  display: flex;
  align-items: center;
  width: 100%;
  border-bottom: 1px white solid;
  height: 15%;
}

.input_title input {
  margin-top: 5px;
  width: 92%;
  height: 50px;
  color: white;
  outline: none;
  border: none;
  background-color: transparent;
  margin-left: 10px;
}

.input_title input::placeholder {
  font-weight: 800;
  font-size: 18px;
  color: white;
}

.input_content {
  width: 100%;
  height: 85%;
  position: relative;

  /* background-color: blue; */
}

.content_container {
  width: 100%;
  height: flex;
  /* background-color: blue; */
}

.content_container ol {
  padding-top: 20px;
}

/* .content_container ol>li {
    padding: 10px;
} */

.input_content textarea {
  width: 93%;
  height: auto;
  scrollbar-width: none;
  resize: none;
  display: block;
  color: white;
  outline: none;
  border: none;
  background-color: transparent;
  padding-top: 10px;
  margin-left: 10px;
  margin-top: 10px;
}

.input_content textarea::placeholder {
  color: white;
  font-size: 14px;
  font-weight: 500;
}

.submit_area {
  width: 100%;
  margin: 0 auto;
  text-align: center;
  position: absolute;
  bottom: 0px;
  left: 50%;
  transform: translateX(-50%);
  /* background-color: blue; */
}

.submit_area h6 {
  margin-top: 10px;
}

.submit_button {
  border: none;
  margin: 0 auto;
  color: white;
  width: 90%;
  height: 50px;
  background-color: #1b1b1b;
  border-radius: 10px;
}

.input_button_container {
  position: absolute;
  bottom: 120px;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 100px;
  /* background-color: gold; */
}

.input_button_container input {
  margin: 0 25px;
  color: white;
  text-align: center;
  border: none;
  outline: none;
}

.input_button_container input::placeholder {
  color: white;
  text-align: center;
}

.station {
  width: 100%;
  height: 150px;
  padding-top: 10px;
}

.station input {
  margin: 0 auto;
  display: block;
  width: 93%;
  height: 50px;
  margin-top: 5px;
  border-radius: 5px;
  background-color: gray;
  opacity: 0.9;
  color: white;
  outline: none;
  border: none;
}

.station input::placeholder {
  color: white;
}

.input_quiz_box {
  /* list-style-type: none; */
  margin: 0 auto;
  width: 80%;
  height: 50%;
  padding-top: 30px;
  /* background-color: blue; */
  /* border-radius: 15px; */
}

.input_quiz_box input {
  margin: 0 auto;
  display: block;
  width: 80%;
  padding: 12px 20px;
  background-color: seagreen;
  border: 1px white solid;
}

.input_quiz_box input::placeholder {
  color: white;
}

.input_quiz_box input:first-child {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.input_quiz_box input:last-child {
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}

.feedbox1,
.feedbox2,
.feedbox3 {
  width: 380px;
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
  opacity: 1;
  /* transition: transform 0.5s ease-in-out; */
}

.feedbox1 {
  bottom: 30px;
}

.feedbox2 {
  bottom: 80px;
  box-shadow: 0px 2px 6px black;
  /* transform: translate(-50%, -100px); 
    opacity: 0;  */
}

.feedbox3 {
  bottom: 30px;
}

.animate {
  transform: translate(-50%, -100px);
  opacity: 0;
}

.up_animate {
  transform: translate(-50%, -50px);
  box-shadow: 0px 2px 6px black;
}

.line {
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  /* background-color: #2A2577; */
  /* padding-bottom: 5px; */
  width: 95%;
  margin-top: 10px;
  border-bottom: 1px solid #ffffff;
}

.comment_function {
  width: 30%;
  display: flex;
  justify-content: space-between;
}

.comment_container {
  /* background-color: blue; */
  padding-bottom: 20px;
}

.comment_box {
  margin: 0 auto;
  width: 95%;
  display: flex;
  align-items: center;
  font-size: 15px;
  /* padding-top: 5px; */
  /* background-color: blue; */
  margin-top: 10px;
  padding-left: 16px;
}

.comment_support {
  /* background-color: blue; */
  height: auto;
  color: #ffffff;
  width: 115px;
  margin-right: 20px;
  font-size: 13px;
}

.no_comment {
  align-self: center;
}

.comment_data {
  color: gray;
  font-size: 13px;
}

/* .comment_input {
    margin-top: 10px;
    width: 100%;
    height: 30px;
    border-radius: 100px;
} */

.comment_action {
  display: flex;
  margin: 0 auto;
  width: 95%;
  position: relative;
  margin-top: 10px;
}

.comment_action input {
  width: 100%;
  height: 30px;
  border-radius: 100px;
  color: white;
  background-color: #787878;
  border: 0;
  box-shadow: inset 0px 2px 5px rgb(0 0 0 / 19%);
}

.comment_action button {
  position: absolute;
  right: 5px;
  top: 5px;
  bottom: 5px;
  width: 80px;
  border: none;
  background-color: transparent;
  cursor: pointer;
  color: #ffffff;
  font-size: 12px;
}

.function_box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.action_btn {
  margin-left: 16px;
  display: flex;
  /* width: 30%; */
  /* justify-content: space-around; */
  /* background-color: gold; */
}

.show_body {
  font-size: 12px;
  cursor: pointer;
  color: #787878;
  /* width: auto; */
  /* justify-content: flex-start; */
  /* margin-left: 12px; */
  /* background-color: blue; */
}

.report_btn {
  margin-left: 10px;
  cursor: pointer;
  color: #787878;
  font-size: 12px;
}

/* 숏폼형태 */

.test {
  height: 92%;
  /* width: 100%; */
  /* display: flex; */
  /* justify-content: center;
    align-items: center; */
  /* z-index: 99; */
  /* overflow: hidden; */
  /* background-color: blueviolet; */
}

.short_form {
  /* min-height: 100vh; */
  height: 100dvh;
  width: 100%;
  display: flex;
  color: black;
  border: 1px solid #2c2c2c;
  box-sizing: border-box;
  /* 새로고침 테스트 */
  overscroll-behavior: none;
  -webkit-user-select: none;
  user-select: none;
  /* background-color: blanchedalmond; */
}

.short_form_write {
  flex-direction: column;
  height: 110dvh;
  background-color: #131313;
}

.short_form_container {
  margin: 0 auto;
  width: 80%;
  padding-bottom: 10px;
  background-color: gray;
  border-radius: 10px;
  transition: transform 0.7s ease;
}

.write_form_container {
  height: 700px;
}

.button_area {
  position: absolute;
  top: 10dvh;
  width: 100%;
  height: 660px;
  /* background-color: aquamarine; */
}

.comment_top {
  margin: 0 auto;
  /* display: flex; */
  text-align: center;
  margin-bottom: 10px;
}

.gray_bar {
  margin: 0 auto;
  margin-top: 15px;
  margin-bottom: 10px;
  background-color: gray;
  width: 40%;
  height: 10px;
  border-radius: 100px;
}

.comment_window {
  position: absolute;
  border-radius: 15px;
  z-index: 5;
  top: 44%;
  width: 100%;
  height: 370px;
  background-color: white;
  color: black;
  /* background-color: #2A2577; */
}

.comment_body {
  width: 100%;
  /* display: flex; */
  /* background-color: greenyellow; */
}

.comment_action form {
  width: 100%;
}

.comment_box1 {
  /* background-color: black; */
  /* padding: 0; */
  overflow: scroll;
  height: 220px;
  scrollbar-width: none;
  /* overscroll-behavior: inherit; */
  /* display: flex;
    align-items: center;
    justify-content: space-between; */
}

/* .comment {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 100px;
    background-color: orange;
} */

.all_comments {
  display: flex;
  justify-content: space-between;
  /* background-color: blueviolet; */
  width: 100%;
  height: 30px;
}

.short_box {
  width: 100%;
  height: auto;
  position: relative;
  /* background-color: greenyellow; */
}

.write_short_box {
  height: 100%;
}

.short_feed {
  color: black;
  margin: 0 auto;
  /* height: 490px; */
  width: 97%;
  /* background-color: greenyellow; */
  border-radius: 15px;
  position: relative;
  z-index: 0;
  padding-top: 75px;
}

.text_body {
  margin-top: 20px;
  padding-top: 10px;
  height: 500px;
  margin: 0 auto;
  width: 100%;
  /* background-color: blue; */
  margin-bottom: 30px;
}

.text_body > #content {
  color: white;
  font-size: 16px;
  margin-bottom: 5px;
}

.text_body textarea {
  /* padding: 0; */
  margin: 0;
  background-color: #000000;
  /* padding-top: 5px; */
  padding: 12px;
  /* padding-left: 5px; */
  width: calc(100% - 24px);
  height: 450px;
  border: 0;
  outline: none;
}
/* 이미지편집과 선택지편집 스타일 */
.click-icon {
  display: flex;
  justify-content: space-evenly;
}
.icon-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  img {
    width: 30px;
    height: 30px;
  }
  span {
    color: #ffffff;
    font-size: 12px;
    margin-block: 12px;
  }
}
/* 하단 댓글허용 등등 스타일 */
.func_part {
  flex-grow: 0.3;
  /* width: 100%; */
  /* background-color: green; */
}

.btn_func {
  /* height: 50px; */
  /* background-color: lightcoral; */
  margin-right: auto;
  display: flex;
}

.btn_func label {
  margin-right: 10px;
  color: gray;
}

.btn_func_area {
  display: flex;
  align-items: center;
  height: 50px;
  width: 90%;
  padding-inline: 15px;
  /* background-color: #2A2577; */
}
.line_write {
  opacity: 1;
  height: 2px;
  background-color: white;
}
/* .custom-checkbox {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.custom-checkbox input[type="checkbox"] {
  display: none;
}

.custom-checkbox:before {
  content: "";
  display: inline-block;
  width: 16px;
  height: 16px;
  border: 2px solid #98a0ff;
  border-radius: 4px;
  margin-right: 8px;
  transition: background 0.3s, border-color 0.3s;
}

.custom-checkbox input[type="checkbox"]:checked + span {
  background: #98a0ff;
  border-color: #98a0ff;
} */

/* ------------------ */

.write_container {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  /* background-color: orange; */
}

.warning_text {
  text-align: center;
  font-size: 12px;
  color: white;
  margin-top: 20px;
}

.write_feed {
  background-color: white;
  border-radius: 15px;
}

.write_body {
  padding: 0;
  padding-top: 5px;
  padding-left: 5px;
  width: 95%;
  height: 100px;
  border-radius: 10px;
  resize: none;
  color: white;
}

.write_select {
  /* margin: 0 auto; */
  width: 85%;
  height: 50px;
  /* display: flex;
    justify-content: space-between;
    align-items: center; */
  border: 1px solid #bebebe;
  border-radius: 10px;
  background-color: white;
}

/* 넷 중 하나 */
.one_of_four_area {
  margin: 0 auto;

  /* border-radius: 15px; */
  padding: 0;
  width: 90%;
  height: 80%;
  /* background-color: greenyellow; */
  display: flex;
  align-items: center;
}

.one_of_four_list {
  list-style-type: none;
  padding: 0;
  width: 100%;
  /* height: 100%; */
  /* background-color: blue; */
  /* border-radius: 15px; */
}

.one_of_four_list li {
  /* display: flex; */
  padding: 5px 15px;
  background-color: #000000;
  /* height: 100%; */
  /* padding: 10px; */
  border: 2px #8f8f8f solid;
}

.one_of_four_list li:first-child {
  /* border-top-left-radius: 15px;
  border-top-right-radius: 15px; */
  border-bottom: 0.8px;
}

.one_of_four_list li:nth-child(2) {
  border-bottom: 0.8px;
}

.one_of_four_list li:last-child {
  border-top: 0.8px;
  /* border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px; */
}

.one_of_four_list input {
  width: 98%;
  background-color: #000000;
  border: 0;
  height: 30px;
  color: white;
  &:focus {
    outline: none;
  }
}

.one_of_four_list input::placeholder {
  color: white;
  font-size: 12px;
}

/* 둘 중 하나 */

.balance_btn {
  text-align: center;
  width: 40%;
  height: 60px;
  cursor: text;
  color: black;
  background-color: #000000;
  border: 1px solid #ababab;
  color: white;
}

.balance_btn::placeholder {
  color: white;
}

.balance_btn:focus {
  outline: none;
}

/* 정거장 */
.station_container {
  /* margin-top: 30px; */
  /* background-color: blue; */
}

.station_box {
  /* margin-left: 20px; */
  margin: 0 auto;
  background-color: #000000;
  width: 85%;
  height: 100px;
  border-radius: 10px;
  color: white;
  border: 1px white solid;
  padding: 5px 10px;
}

.station_box input {
  width: 80%;
  padding-left: 20px;
  border-radius: 10px;
  border: none;
  background-color: transparent;
  outline: none;
  color: white;
}

.station_box input::placeholder {
  color: white;
  text-decoration: underline;
}

.station_box input:first-child {
  font-size: 26px;
  font-weight: 800;
}

.station_box input:nth-child(2) {
  text-decoration: none;
}

.site_name {
  background-color: blue;
}

.img_circle {
  margin: 0 auto;
  width: 90%;
  height: 200px;
  /* border-radius: 100%; */
  /* background-color: blue; */
  position: absolute;
  /* float: left; */
  left: 0;
  right: 0;
  top: 15px;
  /* top: 10px; */
  z-index: 1;
  /* top: 50px; */
  /* float: left; */
  /* display: flex; */
  text-align: center;
}

.img_circle > img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.function_button {
  position: absolute;
  right: 0px;
  bottom: 22%;
  width: 70px;
  height: 300px;
  /* background-color: gold; */
}

.func_btn {
  text-align: center;
  width: 90%;
  height: 65px;
  /* background-color: transparent; */
  margin-bottom: 20px;
}

.func_btn button {
  width: 100%;
  height: 40px;
  background-color: transparent;
  border: none;
  /* background-color: blueviolet; */
}

.func_btn_img {
  /* background-color: blueviolet; */
  object-fit: cover;
  width: 100%;
  height: 100%;
  color: white;
  cursor: pointer;
}

.func_btn p {
  margin: 0;
  padding: 0;
}

.planet_page_btn_container {
  width: 80%;
}

.planet_page_button {
  width: 120px;
}

/* new */

.banner-slider {
  position: relative;
  width: 300px;
  /* 배너의 너비 */
  height: 100dvh;
  /* 배너의 높이를 화면의 높이에 맞추기 */
  overflow: hidden;
  margin: 0 auto;
  /* X축 중앙에 정렬 */
  cursor: grab;
}

/* 배너들이 쌓이는 트랙 */
.slider-track {
  display: flex;
  flex-direction: column;
  transition: transform 0.5s ease;
}

/* 각 배너의 스타일 */
.slider-item {
  min-height: 100dvh;
  /* 각 박스의 높이를 화면 높이에 맞추기 */
  width: 100%;
  /* 배너의 너비는 부모 요소에 맞춤 */
  display: relative;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 2em;
  /* transition: background-color 0.3s ease; */
  /* user-select: none; */
}

.current-index {
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  color: white;
  font-size: 1.2em;
  background: rgba(0, 0, 0, 0.5);
  padding: 10px;
  border-radius: 8px;
}

/* 댓글 더보기 */
.more_comments {
  color: #ffffff;
  margin: 0 auto;
  margin-top: 10px;
  width: 80%;
  height: 200px;
  border-radius: 10px;
  border: 1px solid gray;
  overflow: scroll;
  scrollbar-width: none;
}

.comments_box {
  width: 100%;
  height: 50px;
  /* background-color: antiquewhite; */
  margin-bottom: 5px;
}

.feed_comments_box {
  margin: 0 auto;
  width: 90%;
  margin-top: 15px;
}

.comment {
  /* display: flex;
    justify-content: center;
    align-items: center; */
  margin-bottom: 5px;
  margin-left: 10px;
}

.user_name {
  margin-top: 5px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* margin-left: 10px; */
}

.interaction_btn {
  width: 120px;
  /* background-color: greenyellow; */
  display: flex;
  margin-right: 15px;
  align-items: center;
  justify-content: space-between;
}

.comment_text {
  color: gray;
}

.delete_btn {
  /* flex-grow: 1; */
  /* flex: ; */
  cursor: pointer;
  /* height: 100%;
    width: 100%; */
  /* background-color: blue; */
}

.report_star_btn {
  flex: 0.8;
  /* width: 50%; */
  display: flex;
  justify-content: space-between;
  /* background-color: blueviolet; */
}

.report {
  cursor: pointer;
}

.star_num {
  /* height: 50px; */
  display: flex;
  text-align: center;
  padding: 0px;
  margin: 0px;
  justify-content: center;
  align-items: center;
  /* background-color: blue; */
}

/* .num_star {
    font-size: 14px;
    padding: 0px;
    margin-top: 0px;
} */

.like_btn {
  margin-right: 10px;
  /* background-color: greenyellow; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.num_like {
  color: #787878;
}

.like {
  margin-right: 5px;
  cursor: pointer;
}

.comment_like {
  margin-right: 1px;
  cursor: pointer;
}

.star_color {
  color: red;
  background-color: green;
}

/* 이미지 영역 */
.home_feed_img {
  width: 100%;
  /* background-color: blue; */
  /* height: 200px; */
  margin-bottom: 20px;
  /* height: 200px; */
}

.home_feed_img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.close_btn {
  position: absolute;
  top: 15px;
  right: 20px;
  cursor: pointer;
  /* background-color: blue; */
}

.comments_scroll {
  overflow: scroll;
  height: 200px;
}

.write_button {
  width: 50px;
  height: 50px;
  cursor: pointer;
}

.backword {
  width: 35px;
  height: 35px;
  filter: invert();
  cursor: pointer;
}

.order_btn {
  width: 25px;
  height: 25px;
  cursor: pointer;
  margin-left: 10px;
  margin-right: 10px;
}

.fclass_btn {
  /* background-color: blue; */
  display: flex;
  justify-content: space-between;
  color: #98a0ff;
  align-items: center;
  width: 100%;
}

.fclass_btn img.order_btn:nth-of-type(2) {
  transform: scaleX(-1);
}

/* 댓글입력 창 - 댓글더보기에서 */
.comment-input {
  position: absolute;
  bottom: 10px;
  left: 10px;
}

/* 해시태그 붙이고 삭제 테스트*/
.tag-container {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
}

.tag-box {
  background-color: #98a0ff;
  color: white;
  padding: 2px 5px;
  margin-right: 5px;
  margin-bottom: 5px;
  display: inline-block;
  height: 20px;
  font-size: 15px;
}

.delete-tag {
  background: none;
  border: none;
  color: black;
  cursor: pointer;
  margin-left: 5px;
  font-size: 1.2em;
}

/* 업로드 버튼 */
.upload-btn {
  padding: 10px 15px;
  background-color: #98a0ff;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}

.empty-box {
  height: 30px;
}
/* 모달 창 스타일 */

/* 모달 오버레이 */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* background-color: rgba(0, 0, 0, 0.5); 반투명 배경 */
  display: flex;
  justify-content: center;
  align-items: end;
  z-index: 9999;
}

/* 모달 콘텐츠 */
.modal-content {
  background-color: #141517;
  padding: 10px;
  border-radius: 8px;
  width: 80%;
  max-width: 450px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 100px;
}

/* 모달을 클릭한 곳만 닫히지 않도록 처리 */
.modal-content button {
  margin-top: 10px;
  padding: 10px;
  background-color: #98a0ff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
.modal-title {
  font-size: 20px;
  color: white;
  text-align: center;
}
.modal-content button:hover {
  background-color: #6f7bff;
}
/* --------라이트모드------- */
.comment-box.bright-mode {
  background-color: #ffffff;
  border: 1px solid #787878;
  color: black;
}
.feed-text.bright-mode {
  color: black;
}
.comment-write.bright-mode {
  color: black;
}
.line.bright-mode {
  border-color: #d2c8f7;
}

/* 피드 작성 페이지  */
.write_container.bright-mode {
  .warning_text {
    color: #000000;
  }
}
.short_form_write.bright-mode {
  background-color: #eaeeff;
  p {
    color: #000000;
  }
}
.input-area.bright-mode {
  background-color: #ffffff;
  border-color: #bebebe;
  #hashtag {
    color: #000000;
  }
  .write-tag {
    border-color: #cccccc;
    background-color: #ffffff;
  }

  #content {
    color: #000000;
  }
  .text_body textarea {
    background-color: #ffffff;
  }
  .write_body {
    color: #000000;
  }
}
/* 이미지편집 선택지 편집 */
.click-icon.bright-mode {
  img {
    filter: invert();
  }
  span {
    color: black;
  }
}
.line_write.bright-mode {
  background-color: #ffffff;
  border: 1px solid white;
}

/* 모달 창 */
.modal-overlay.bright-mode {
  .modal-content {
    background-color: #e3e7f9;
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.25);
  }
}
.write-image-box.bright-mode {
  background-color: #ffffff;
  span {
    color: #000000;
  }
  .upload-text {
    div {
      color: #000000;
    }
  }
}
/* 버튼 하나 */
.fclass-container.bright-mode {
  .empathy-box {
    background-color: white;
    color: black;
  }
  .empathy-box::placeholder {
    color: black;
  }
}
/* 4지선다 */
.one_of_four_area.bright-mode {
  .one_of_four_list {
    li {
      background-color: white;
    }
    input {
      background-color: white;
      color: black;
    }
  }
  input::placeholder {
    color: black;
  }
}

/* 버튼 두개 */
.button_container.bright-mode {
  .balance_btn {
    background-color: white;
    color: black;
    &::placeholder {
      color: #000000;
    }
  }
}
/* 사이트 링크 */
.station_container.bright-mode {
  .station_box {
    background-color: white;

    input {
      color: black;
    }
    input::placeholder {
      color: #000000;
    }
  }
}

/* 숏피드 페이지 */
.short_form.bright-mode {
  border: 0;
}
.content-box.bright-mode {
  background-color: white;
}
.nick_name.bright-mode {
  color: black;
}
.hashtag-box.bright-mode {
  color: #9f80ff;
}
.feed-content.bright-mode {
  color: black;
}
.interaction-box.bright-mode {
  background-color: #f0f0f0;
  img {
    filter: invert(1);
  }
  #text {
    color: #000000;
  }
}
/* 버튼들 */
.fclass-container.bright-mode {
  h1,
  h5 {
    color: black;
  }

  .empathy-box {
    div {
      color: #000000;
    }
  }
}

/* 모달 창 라이트 */
.modal-container.bright-mode {
  .comment-modal {
    background-color: #f2f2f2;
  }
  .comment-more-see {
    border-color: #dbdbdb;

    nav {
      color: #797979;
    }
  }
  .not-comment {
    color: #797979;
  }
  .comment_action input {
    background-color: #ffffff;
    color: #797979;
  }
  .comment_action button {
    color: #797979;
  }
  .text-2 {
    color: #797979;
  }
  .button-modal {
    color: #797979;
  }
}
/* 댓글 닉네임 */
.comment_support.bright-mode {
  color: #121212;
}

/* 2024/12/30 */
/* 피드 레이아웃 수정본 */
.wrapper-container {
  width: 100%;
  cursor: pointer;
  background-color: white;
  color: black;
  margin-bottom: 15px;
  border: 1px 0px solid gray;
  position: relative;
}

.long-wrapper {
  /* min-height: 600px; */
  /* min-height: 300px; */
}

.user-container {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  div {
    font-size: 15px;
    color: #797979;
  }
}

.body-container {
  padding: 0px 20px;

  .body-hashtag {
    color: #9f80ff;
    padding-bottom: 20px;
    font-weight: 200;

    span {
      margin-right: 10px;
    }
  }

  .body-content {
    font-weight: 400;
    word-wrap: break-word;
  }
}

.button-container {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  align-items: center;
  div {
    font-size: 12px;
    color: #797979;
  }
}

.button-box1 {
  display: flex;
  align-items: center;
  gap: 20px;
}

.action-button {
  display: flex;
  align-items: center;
  gap: 5px;
  /* justify-content: center; */

  button {
    all: unset;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    img {
      width: 20px;
      height: 20px;
      object-fit: cover;
    }
  }
}

/* 옵션 선택 */
.option-container {
  display: flex;
  flex-direction: column;
  padding: 10px 0px;
  justify-content: center;
  /* align-items: center; */

  .option {
    cursor: pointer;
    width: 100%;
    border-radius: 15px;
    padding: 15px;
    background-color: #d2c8f7;
  }
}

/* 퀴즈 선택 */
.quiz-container {
  padding: 10px;
  list-style-type: none;
  li {
    display: flex;
    flex: 1;
    background-color: #ffffff;
    padding: 15px;
    border-radius: 12px;
    border: 1px solid gray;
    margin-bottom: 5px;
  }
}

/* 롱폼 */
.long-form-container {
  width: 100%;
  min-height: 250px;
  height: 40%;
  background-color: white;
  position: absolute;
  bottom: 0px;
  overflow: hidden;
  box-shadow: 0px -50px 200px 0px gray;
}

.action-container {
  width: 100%;
  /* background-color: greenyellow; */
}

.action-box {
  width: 80%;
  height: 37px;
  cursor: pointer;
  margin: 0 auto;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: content-box;
  border: 1px solid gray;
  border-radius: 15px;
  margin-top: 10px;
}

.action-result {
  width: 100%;
  padding: 9px 20px;
  border-radius: 15px;
  margin: 0;
  box-sizing: content-box;
  background-color: #dbeaff;
}

.action-points {
  position: absolute;
  right: 10px;
  /* right: 10px; */
}

.long-form-hidden {
  max-height: 500px;
  overflow: hidden;
}

.Link_Container {
  cursor: pointer;
  width: 100%;
  /* height: 200px; */
  margin-bottom: 50px;
  /* padding: 0 20px; */
  /* background-color: gray; */
}

.Link_box {
  height: 100%;
  border: 1px solid black;
  margin: 0 20px;
  margin-bottom: 10px;
  border: none;
}

.Link_thumbnail {
  height: 200px;
  img {
    border: none;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

.Link_info {
  padding: 10px;
  border: solid 1px #aad4ff;
  border-top: none;

  .Link_title {
    margin-bottom: 5px;
  }

  .Link_domain {
    font-size: 12px;
    color: #0076ee;
  }
}

.Link_explain {
  /* vertical-align: middle; */
  display: flex;
  align-items: center;
  gap: 5px;
  line-height: 15px;
  margin: 0 20px;
  color: #3096ff;
  font-size: 14px;

  img {
    display: flex;
    width: 15px;
    height: 100%;
    object-fit: cover;
  }
}

.link-line {
  display: flex;
  flex-direction: column;
  p {
    margin: 0;
    margin-bottom: 15px;
    padding: 0;
    text-align: center;
    color: #7c7c7c;
    font-size: 13px;
    font-variation-settings: "wght" 200;
  }
}

.hr-sect {
  display: flex;
  align-items: center;
  flex-basis: 100%;
  font-size: 14px;
  margin: 8px 0px;
  font-variation-settings: "wght" 200;
}
.hr-sect::before,
.hr-sect::after {
  content: "";
  flex-grow: 1;
  background: #aad4ff;
  height: 1px;
  font-size: 0px;
  line-height: 0px;
  margin: 0px 20px;
}

.AI_container {
  /* width: 100%; */
  padding: 0px 20px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #949494;
  font-size: 14px;

  button {
    all: unset;
    cursor: pointer;
    text-decoration: underline;
  }
}

.AI_text_info {
  /* vertical-align: middle; */
  display: flex;
  align-items: center;
  gap: 5px;
  line-height: 15px;

  img {
    display: flex;
    /* width: 15px; */
    /* height: 100%; */
    object-fit: cover;
  }
}
