.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* 어두운 배경 */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* 모달이 다른 요소들 위에 표시되도록 설정 */

  .modal {
    background-color: #f1f7ff;
    padding: 20px;
    border-radius: 8px;
    width: 300px;
    text-align: center;
    z-index: 1001; /* 모달 내용이 배경보다 위에 오도록 */

    .streamer-img {
      background-color: transparent;
      display: flex;
      justify-self: center;
      width: 141px;
      height: 141px;

      & img {
        border-radius: 50%;
        object-fit: cover;
      }
    }

    p {
      b {
        color: #107bf4;
      }
    }

    span {
      display: flex;
      justify-content: space-evenly;
      gap: 10px;

      button {
        width: 100%;
        margin-top: 15px;
        padding: 10px;
        background-color: white;
        color: black;
        font-weight: 600;
        border: none;
        border-radius: 5px;
        cursor: pointer;

        &:hover {
          filter: brightness(0.9);
        }
      }

      .follow-button {
        background-color: #d4e7ff;
      }
    }
  }
}
