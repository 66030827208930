.ScheduleEvent {
  background-color: white;
  padding: 20px;
  & dl {
    margin: 0;
    color: #797979;
    display: flex;
    flex-flow: column;
    gap: 6px;

    & dt {
      font-size: 12px;
    }

    /* 번들 타이틀 스타일 */
    & dt:first-child {
      color: black;
      font-size: 15px;
      font-weight: 500;
    }

    /* 스케줄 bundle 타이틀 스타일 */
    & .ScheduleBudleTitle {
      display: flex;
      justify-content: space-between;
      align-items: center;

      & p {
        margin: 0;
        font-size: 12px;
      }
    }

    /* bundle 날짜 스타일 */
    & dt:last-child {
      & p {
        margin: 0;
        display: inline;
      }
    }
  }

  .scheduleBox {
    display: flex;
    justify-content: space-between;

    /* 이벤트 자세히 보기 날짜 시간 판매처 */
    & .BnameInfo {
      font-size: 12px;
      display: flex;
      flex-flow: column;
      gap: 4px;

      & .eventDate {
        font-size: 12px;
        font-weight: 300;
        color: #797979;
      }
    }

    /* 이벤트 자세히 보기 0 일전 0 선택 */
    & .rightSection {
      display: flex;
      flex-flow: column;
      justify-content: space-between;
      height: 100%;

      & p {
        margin: 0;
        font-size: 12px;

        &:last-child {
          font-weight: 300;
          color: black;
        }
      }
    }
  }
}
