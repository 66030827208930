.ExploreSchedulePage {
  background-color: #f1f7ff;
}
/* 상단 바 */
.navBar {
  background: #fff;
  display: flex;
  align-items: center;

  & button {
    background-color: transparent;
    border: 0;
    display: flex;
    align-items: center;
    gap: 6px;
    margin-inline: 17px 28px;
    & img {
      width: 8px;
    }
  }

  & h1 {
    font-size: 20px;
    font-weight: 600;
    color: #2e2e2e;
  }

  & p {
    margin: 0;
    font-size: 12px;
    color: #5c5c5c;
    margin-inline: auto 20px;
    border-bottom: 1px solid currentColor;
  }
}

.button-container {
  display: flex;
  background-color: #fff;
  padding: 21px 23px;
  gap: 20px;
  & button {
    display: flex;
    align-items: center;
    font-size: 14px;
    background: #f1f7ff;
    border: 1px solid #e4e4e4;
    border-radius: 16px;
    padding: 5px 10px 5px 16px;
  }
}

/* 선택 바 */
.type-list {
  margin-bottom: 5px;
  padding-block: 20px 5px;
  background-color: #fff;

  li,
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .post-list {
    position: relative;
    display: flex;
    background-color: #fff;
    padding-inline: 16px;

    .post {
      cursor: pointer;
      margin: 10px 0;
      transition: border-bottom 0.3s ease;
      padding-inline: 18px;
      margin: 0;
      text-align: center;

      & button {
        padding: 0;
        padding-bottom: 7px;
        border-radius: 0;
        font-size: 17px;
        font-weight: 600;
        cursor: pointer;
        position: relative;
        background: none;
        border: none;
        width: 100%;
      }
    }

    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 20px;
      height: 3px;
      border-radius: 5px;
      background-color: #1082f4;
      transition: left 0.3s ease-in-out;
    }
  }

  .post-list[data-active-index="0"]::after {
    left: 40px;
  }
  .post-list[data-active-index="1"]::after {
    left: 105px;
  }
  .post-list[data-active-index="2"]::after {
    left: 170px;
  }
  .post-list[data-active-index="3"]::after {
    left: 235px;
  }
  .post-list[data-active-index="4"]::after {
    left: 308px;
  }
  .post-list[data-active-index="5"]::after {
    left: 381px;
  }
}

/* 모달 창 */
.EventMoreContainer {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 10001;
  background-color: transparent;
  opacity: 0;
  transform: translateY(100%);
  transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
  pointer-events: none;
}

.eventMain {
  background-color: #fff;
  width: 100%;
  height: 70vh;
  margin-top: 471px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  transition: transform 0.5s ease-in-out;
  transform: translateY(100%);

  & h3 {
    text-align: center;
    padding-block: 20px;
    font-weight: 500;
    font-size: 17px;
  }
}

/* 올라가고 내려가는 애니메이션 */
.see {
  opacity: 1;
  transform: translateY(0);
  pointer-events: auto;
  transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
}
.on {
  transform: translateY(0);
}

/* 체크박스 선택 */
.form-container {
  display: flex;
  flex-flow: column;
  gap: 40px;
  padding-inline: 30px;

  & label {
    font-size: 20px;
    font-weight: 500;
    display: flex;
    justify-content: space-between;

    & span {
      & p {
        margin: 0;
      }
      & p:nth-child(2) {
        font-weight: 300;
        font-size: 18px;
        color: #797979;
      }
    }

    & input {
      appearance: none;
      width: 25px;
      height: 25px;
      border: 2px solid #afafaf;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      &:checked {
        position: relative;

        &::before {
          content: "";
          width: 15px;
          height: 15px;
          border-radius: 50%;
          background-color: #afafaf;
          position: absolute;
        }
      }
    }
  }
}

/* 일정 리스트 swiper 스타일 */
/* 여기랑 SearchSchedulePage에 index.css랑 겹쳐서 들어감,,, */
.scheduleList {
  height: 30vh;
}

/* 크기 줄어들면 swiper로 변화하도록 함 */

.swiper-type {
  background: #fff;
  padding-inline: 20px;
  display: none;
  padding-block: 20px 5px;
  margin-bottom: 5px;

  & button {
    background-color: transparent;
    border: 0;
    padding: 0;
    padding-bottom: 7px;
    font-size: 17px;
    font-weight: 600;
    cursor: pointer;
    position: relative;
    background: none;
    border: none;
    width: 100%;
  }
  & button.active {
    color: #007aff; /* 활성화된 탭의 색상 */
    font-weight: bold; /* 활성화된 탭을 더 두껍게 */
  }
}

@media (max-width: 530px) {
  /* 이게 display:flex를 쓰고 있어서 display:none이 안먹혀  */
  .type-list {
    display: none;
  }
  .swiper-type {
    display: block;
  }
}
