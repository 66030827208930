.WriteFeed {
  max-width: 500px;
  width: 100%;
  margin: 0 auto;
  min-height: 100vh;
  background-color: #f1f7ff;
  position: relative;
}

.top_container {
  color: #797979;
  display: flex;
  padding: 20px;
  justify-content: space-between;

  .buttons {
    cursor: pointer;
  }
}

.hashtag_container {
  background-color: white;
  /* height: 50px; */
  padding: 20px;
  margin-bottom: 5px;
}
.tag-container {
  margin-top: 5px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  /* background-color: blue; */
}

.tag-icon-box {
  margin-top: 5px;
  width: 25px;
  height: 25px;
  /* background-color: yellow; */

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.tag-box {
  background-color: #98a0ff;
  color: white;
  padding: 2px 5px;
  margin-left: 5px;
  margin-right: 5px;
  margin-top: 5px;
  display: flex;
  align-items: center;
  height: 20px;
  font-size: 15px;
}

.delete-tag {
  background: none;
  border: none;
  color: black;
  cursor: pointer;
  margin-left: 5px;
  font-size: 1.2em;
}

.input-container {
  display: flex;
  margin-top: 10px;
  align-items: center;
  position: relative;
  height: 40px;
}
.input-hashtag {
  background-color: white;
  color: #898989;
  /* width: 100%; */
  height: 70%;
  margin-inline: 5px;
  padding: 5px;
  border: none;
  border-bottom: 1px solid gray;
}

.input-wrapper {
  width: 95%;
  /* background-color: blue; */
  display: flex;
  flex-direction: column;
  flex: 1;
}
.button-wrapper {
  /* background-color: blue; */
  height: 100%;
}
.check-button {
  all: unset;
  /* color: white; */
  /* background-color: blue; */
  width: 30px;
  /* height: 100%; */
  font-size: 16px;
}

.count-text {
  color: #5e5e5e;
  font-size: 15px;
  display: flex;
  justify-content: end;
  margin-right: 5px;
  margin-top: 5px;
}

.content_container {
  background-color: white;
  height: 500px;
  display: flex;
  flex-direction: column;
  /* padding: 20px; */

  .content-title {
    height: 20px;
    padding: 20px;
    /* padding-bottom: 5px; */
  }

  .content-title-long {
    /* margin-bottom: 10px; */
    padding: 10px 20px;
  }

  .write_body {
    all: unset;
    /* background-color: blue; */
    border: none;
    padding: 0px 20px;
    /* padding-top: 10px; */
    /* margin-left: 20px; */
    /* width: 100%; */
    flex: 1;
    word-wrap: break-word;
    overflow-wrap: break-word;
    white-space: break-spaces;
    /* height: 100%; */
    border-radius: 10px;
    resize: none;
    color: black;
  }
}

.alert_message {
  padding: 10px 0;
  text-align: center;
  color: black;
}

.content_button {
  background-color: white;
  position: sticky;
  z-index: 999;
  display: flex;
  justify-content: start;
  margin: 0 auto;
  width: 100%;
  bottom: 0px;
  left: 0px;
  gap: 20px;
  font-size: 10px;

  button {
    all: unset;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 5px 0px;
    cursor: pointer;

    img {
      width: 40px;
      height: 40px;
      object-fit: cover;
    }
  }

  button:first-child {
    margin-left: 20px;
  }
}

/* 모달 창 css */
.wrapper-container {
  /* width: 100%; */
  height: 100vh;
  position: fixed;
  display: flex;
  align-items: end;
  justify-content: center;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: 1002;
  background-color: rgba(0, 0, 0, 0.5);
  /* background-color: blue; */
}

.modal-container {
  max-width: 500px;
  /* overflow-y: scroll; */
  width: 100%;
  height: 500px;
  margin: 0 auto;
  background-color: white;

  .modal-title {
    padding: 30px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.link-box-container {
  height: 200px;
}

.image-container {
  height: 300px;
  overflow-y: scroll;
  scrollbar-width: none;
}

#image-file[type="file"] {
  display: none;
}

.input-image {
  margin: 0 auto;
  width: 90%;
  height: 70px;
  display: flex;
  background-color: white;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: dotted 2px #c5deff;
  margin-bottom: 10px;
}

.preview-container {
  margin: 0 auto;
  width: 90%;
  height: 70px;
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  border: dotted 2px #c5deff;
  margin-bottom: 10px;

  .img-name {
    flex: 1;
  }
}

.preview-image {
  width: 50px;
  height: 50px;
  margin-right: 10px;
  /* background-color: blue; */

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

.modal-buttons {
  padding: 20px;
  display: flex;

  button {
    border: 1px solid #e4e9ee;
    padding: 10px;
    border-radius: 3px;
    background-color: #ffffff;
    font-weight: bold;
    /* margin-right: 10px; */
  }

  .close_button {
    width: 30%;
    margin-right: 10px;
  }

  .apply_button {
    width: 70%;
  }

  .apply_button_on {
    color: #1a80e6;
    background-color: #f1f7ff;
    border: 1px solid #359aff;
  }
}

/* 투표 모달 */
.vote-option-wrapper {
  display: flex;
  position: relative;
}
#vote-option[type="text"] {
  all: unset;
  box-sizing: border-box;
  display: flex;
  flex: 1;
  margin: 0 20px;
  padding: 15px;
  padding-left: 30px;
  border-radius: 10px;
  border: 2px solid #e4e9ee;
  margin-bottom: 10px;
}

.delete-option {
  all: unset;
  position: absolute;
  left: 20px;
  top: 18px;
  cursor: pointer;
}

.option-box {
  /* width: 90%; */
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  margin: 0px 20px;
  padding: 15px;
  /* font-size: 14px; */
  border-radius: 10px;
  border: dotted 2px #c5deff;
  cursor: pointer;
}

/* 좌표 모달 */

.link-box {
  border: 1px solid #9ba3ae;
}
.link_explain {
  flex: 1;
}

.link-image {
  width: 30px;
  height: 30px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.link-input-container {
  margin: 0 20px;
  display: flex;
  border: 2px dotted #c5deff;
}

.link-input {
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  input {
    display: inline-block;
    /* flex: 1; */
    border: none;
    border-bottom: 1px solid #b3b3b3;
    padding: 5px 10px;
    margin-bottom: 5px;

    &:focus {
      outline: none;
    }
  }
}

.bias-section {
  .title {
    padding: 10px 20px;
  }
}

.add_link_button {
  cursor: pointer;
  background-color: transparent;
  display: flex;
  align-items: center;
  align-self: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  margin-right: 10px;
  padding: 30px;
  white-space: nowrap;
}

.add_link_button_on {
  border: solid 1px black;
}

.add-icon {
  margin-right: 6px;
  img {
    width: 12px;
    height: 12px;
    object-fit: cover;
  }
}

.remove-icon {
  margin-left: 8px;
  margin-right: 20px;
  display: flex;
  align-items: center;
  img {
    width: 15px;
    height: 15px;
    object-fit: cover;
  }
}

.Select_container {
  /* width: 100%; */
  background-color: white;
  padding: 20px;
  margin-bottom: 5px;
}

.section_title {
  margin-bottom: 5px;
}

.more-find {
  margin-top: 15px;
  text-align: end;
  display: inline-block;
  cursor: pointer;
  color: #797979;
  text-decoration: underline;
}
