.container {
  width: 100%;
  max-width: 500px;
  overflow: hidden;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  /* height: 100%; */
  background-color: #1e1e1e;
  /* border: 1px solid white; */
  position: relative;
}

/* @media (min-width: 1024px) {
  .container {
    max-width: 500px;
  }
} */
.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding-top: 10px;

  padding-bottom: 10px;
  background-color: #000000;
  img {
    filter: invert();
  }
}
.title {
  color: white;
  font-size: 22px;
  font-weight: 500;

  width: 100%;
  padding-block: 20px;
  text-align: center;
}

.tag-container {
  display: flex;
  /* flex-grow: 1; */
  width: 100%;
  margin-bottom: 10px;
  white-space: nowrap;
  overflow-x: auto;
  scrollbar-width: none;
  /* width: 500px; */
  /* overflow: auto; */
  margin-bottom: 10px;
  justify-content: start;
  padding-bottom: 15px;
  height: 100%;
}

.hashtag-text {
  background-color: #373737;
  color: #ffffff;
  border: 0;
  border-radius: 20px;
  padding: 8px 35px;
  min-width: 80px;
  flex-shrink: 0;
  margin-left: 10px;
  cursor: pointer;
}
.feed-box {
  width: 100%;
}
.scroll-area {
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  /* overflow: hidden; */
}

.none_feed_scroll {
  height: 100vh;
}
/* ----라이트 모드 ----- */
.feed-box.bright-mode {
  background-color: white;
  color: #000000;
}
.container.bright-mode {
  background-color: #f1f7ff;
  .header {
    background-color: #f1f7ff;
    img {
      filter: none;
    }
  }
}
.title.bright-mode {
  color: #303030;
  background-color: #ffffff;
}
.tag-container.bright-mode {
  background-color: #ffffff;
}
/* 최애 박스 */
.bias-section {
  display: flex;
  flex-direction: column;
  width: 100%;

  h4 {
    font-weight: 500;
    font-size: 18px;
    padding: 0;
    margin: 0;
    margin-bottom: 10px;
    padding-left: 10px;
  }
}
/* 키워드 박스 */
.keyword-section {
  width: 100%;
}
/* 검색 박스 */
.search-section {
  width: 100%;
}

.search-filter {
  display: flex;
  margin: 0 auto;
  justify-content: space-between;
  width: 90%;
  margin-top: -10px;
  margin-bottom: 10px;

  button {
    min-width: 55px;
    width: 62px;
    display: flex;
    align-items: center;
    font-size: 11px;
    height: 27px;
    padding: 6px 10px;
    cursor: pointer;
    border-radius: 8px;
    border: 1px solid rgba(22, 138, 255, 0.35);
    background-color: #f9fbff;
    /* line-height: 15px; */
  }
}

.filter-icon {
  margin-left: 5px;
  display: flex;
  align-items: center;
  img {
    object-fit: cover;
  }
}

.sort-btn-container {
  display: flex;

  button {
    margin-left: 5px;
  }
}

.filter-modal {
  width: 100%;
  height: 100vh;
  position: fixed;
  bottom: 0px;
  left: 0px;
  background-color: rgba(0, 0, 0, 0.5);
}

.category-info {
  display: flex;
  justify-content: space-between;
  /* width: 100%; */
  color: gray;
  padding: 10px 20px;
  border-block: 0.2px solid #d2d2d269;

  p {
    all: unset;
    color: #797979;
    font-weight: 400;
    font-size: 16px;
  }

  .category_change {
    cursor: pointer;
  }
}

.all_container {
  max-width: 500px;
  /* height: auto; */
  /* overflow: auto; */
}

.story_container {
  margin-left: 10px;
  display: flex;
  overflow: auto;
  scrollbar-width: none;
  margin-bottom: 10px;

  .story_wrapper {
    display: flex;
    gap: 10px;
  }
}
