/* 가장 보고싶은 최애 */
.bias-container {
  width: 100%;
  display: flex;
  overflow-x: auto;
  scrollbar-width: none;
  margin-bottom: 41px;
}

.bias-wrapper {
  display: flex;
  gap: 20px;
  padding: 0px 20px;
  cursor: pointer;
}

.bias-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* background-color: blue; */
  .b-name {
    font-size: 12px;
    margin-top: 12px;
    color: #1f2329;
  }

  .clicked {
    margin-top: 5px;
    height: 3px;
    width: 30%;
    border-radius: 3px;
    background-color: #1082f4;
  }
}

.bias-box {
  width: 100px;
  height: 100px;
  background-color: gray;
  border-radius: 10px;

  .non-bias {
    width: 100%;
    height: 100%;
    font-size: 13px;
    background-color: white;
    border-radius: 10px;
    border: solid 2px rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
    /* box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.5); */
  }
}

.clicked-img {
  transition: box-shadow ease 0.5s;
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.5);
}

.add-bias-box:hover {
  
  background-color: #649BD9;
}

.add-bias-box {
  all: unset;
  cursor: pointer;
  /* padding: 5px; */
  border-radius: 10px;
  /* background-color: #aad4ff; */
  width: 97px;
  height: 97px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: dotted 2px #71b7ff;
  transition: background-color 0.1s ease-in-out;
  img {
    border-radius: 25px;
    width: 30%;
    height: 30%;
    object-fit: cover;
  }
}
