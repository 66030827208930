.FeedDetail {
  width: 100%;
  background-color: #f1f7ff;
  max-width: 500px;
  min-height: 100vh;
  margin: 0 auto;
  position: relative;
}

.top-container {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0px;
}

.delete-button {
  /* padding: 10px 20px; */
  all: unset;
  margin-right: 25px;
  cursor: pointer;
  img {
    /* margin-right: 10px; */
    width: 20px;
    height: 20px;
  }
}

.back-button {
  all: unset;
  cursor: pointer;
  display: flex;
  margin-left: 15px;
  align-items: center;
  img {
    /* filter: invert(1); */
    margin-right: 10px;
    width: 10px;
    height: 20px;
  }
}

/* 댓글 목록 */
.comment-container {
  /* display: flex; */
  width: 100%;
  background-color: white;
}

.title-box {
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: solid 2px #f1f7ff;
}

.comment-title {
  font-weight: 600;
}

.comment-total {
  font-weight: 300;
}

.comment-box {
  min-height: 100px;
  border-bottom: solid 1px #e7e7e7;

  .comment-section {
    /* height: 100%; */
    padding: 10px 20px;
    box-sizing: border-box;
  }
}

.reply-exist {
  border-bottom: 1px solid #f1f7ff;
}

.reply-box {
  /* background-color: rgba(0, 0, 0, 0.1); */
  width: 100%;
  min-height: 60px;
  margin-left: 19px;
  padding-top: 10px;
  & p {
    margin: 0;
    font-size: 14px;
    color: #121212;
  }
  & span {
    text-align: right;
  }
}

.img-container {
  padding-inline: 20px;
  display: flex;
  align-items: start;
}

.comment-user {
  display: flex;
  justify-content: space-between;

  .user-name {
    font-weight: 300;
  }
}

.comment-content {
  margin-block: 5px 0;
}
.date-st {
  width: 100%;
  display: inline-flex;
  justify-content: end;
  color: #828282;
  font-size: 13px;
}
.action-container {
  display: flex;
  justify-content: end;
}

.button-box1 {
  display: flex;
  align-items: center;
  gap: 20px;
}

.action-button {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 10px;
  /* justify-content: center; */

  button {
    all: unset;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    img {
      width: 20px;
      height: 20px;
      object-fit: cover;
    }
  }
}

.input-container {
  width: 100%;
  margin-top: 30px;
  position: sticky;
  bottom: 0px;
  background-color: white;
  padding: 15px 0px;
  #comment {
    width: 90%;
    padding: 10px 0px;
    padding-left: 20px;
    padding-right: 40px;
    display: flex;
    margin: 0 auto;
    border-radius: 20px;
    box-sizing: border-box;
    border: none;
    background-color: #ededed;
  }

  #comment:focus {
    outline: none;
  }

  #comment::placeholder {
    padding: 5px;
    color: #ababab;
  }
}

.input-wrapper {
  position: relative;
}

.input-button {
  width: 20px;
  height: 20px;
  background-color: transparent;
  border-radius: 100px;
  border: #cdcdcd 1px solid;
  position: absolute;
  right: 35px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  img {
    width: 10px;
    height: 10px;
    object-fit: cover;
  }
}

/* modal */
.OptionModal {
  margin: 0 auto;
  /* max-width: 500px;
  width: 500px; */
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 9999;
}

.modal_container {
  /* margin: 0 50px; */
  position: absolute;
  left: 50%;
  top: 30%;
  transform: translateX(-50%);
  max-width: 400px;
  background-color: white;
  width: 100%;
  border-radius: 7px;
  /* height: 300px; */

  .modal_content:last-child {
    border-bottom: none;
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
  }
}

.modal_title {
  padding: 8px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #79797977;
}

.modal_content {
  cursor: pointer;
  padding: 8px 0px;
  border-bottom: 1px solid #797979;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal_content_accent {
  background-color: #f27b7b;
  color: white;
}

.function_button_container {
  display: flex;
  gap: 15px;
  color: #979797;
  .AI_text {
    all: unset;
    cursor: pointer;
    text-decoration: underline;
  }

  .comment-delete-report {
    cursor: pointer;
    text-decoration: underline;
  }
}

.loading-st {
  position: fixed;
  top: 300px;
  left: 42%;
  z-index: 10;
  background: white;
  padding: 10px;
  border-radius: 5px;
}
