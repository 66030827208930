.info-list {
  .post-list {
    position: relative;
    border-top: 1px solid #1082f48c;
    display: flex;
    background-color: #fff;
    padding-inline: 16px;

    li,
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
    }

    .post {
      cursor: pointer;
      margin: 10px 0;
      transition: border-bottom 0.3s ease;
      padding-inline: 18px;
      margin: 0;
      text-align: center;

      & button {
        margin-block: 15px;
        padding: 0;
        padding-bottom: 4px;
        border-radius: 0;
        font-size: 15px;
        font-weight: 600;
        cursor: pointer;
        position: relative;
        background: none;
        border: none;
        font-weight: bold;
        width: 100%;
      }
    }

    &::after {
      content: "";
      position: absolute;
      bottom: 15px;
      left: 0;
      width: 20px;
      height: 3px;
      background-color: #1082f4;
      transition: left 0.3s ease-in-out;
    }
  }

  .post-list[data-active-index="0"]::after {
    left: 43px;
  }
  .post-list[data-active-index="1"]::after {
    left: 118px;
  }
  .post-list[data-active-index="2"]::after {
    left: 194px;
  }
  .post-list[data-active-index="3"]::after {
    left: 262px;
  }
}

.search-nav-bar {
  .post-list {
    &::after {
      bottom: 12px;
      width: 18px;
    }
  }

  .post-list[data-active-index="0"]::after {
    left: 45px;
  }
  /* .post-list[data-active-index="1"]::after {
    left: 125px;
  } */
  /* .post-list[data-active-index="2"]::after {
    left: 203px;
  }
  .post-list[data-active-index="3"]::after {
    left: 273px;
  } */
}
