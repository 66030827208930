.moreContainer {
  background-color: #fff;
  display: flex;
  justify-content: end;
  gap: 10px;
  padding: 0 20px 20px;

  & button {
    border: none;
    width: 40%;
    padding-block: 7px;
    border-radius: 9px;
    color: #5c5c5c;
    font-size: 16px;

    &:last-child {
      background-color: #d0e4ff;
    }

    &:hover {
      filter: brightness(0.9);
    }
  }

  /*버튼 3개일 때 자세히 버튼 */
  & .moreButton {
    width: 15%;
  }
}
