@font-face {
  font-family: "Pretendard";
  src: url("./../public/fonts/PretendardVariable.woff2") format("woff2-variations");
  font-weight: 100 900;
  font-style: normal;
}

body {
  font-family: "Pretendard", sans-serif;
}
/* body {
  margin: 0;
  font-family: "Pretendard-Regular";
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} */
/* 
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
} */
code {
  font-family: "Pretendard";
}
