.time-chart-box {
    display: flex;
    margin-left : 2%;
    width: 98%; /* 부모 컨테이너의 전체 너비 */
}

.day-week-list {
    width: 12%; /* 고정된 너비 */
    flex-shrink: 0; /* 고정 크기를 유지하도록 설정 */
}

.chart-box{
    flex-grow: 1; /* 나머지 공간 차지 */
    background-color: #ffffff; /* 배경색으로 구분 */
}

.chart-etc-box{
    height: 62px;
    background-color: #d0e4ff;
    border-top-left-radius: 12px;;
}

.time-select-box{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 31px;
    background-color: #d0e4ff;
}

.time-select-box img {
    width: 20px; /* 이미지 크기 설정 (필요에 따라 조정) */
}

.time-select-box span {
    flex-grow: 1;
    text-align: center;
    font-size: 13px;
    font-weight: 500;
}

.time-line-box{
    height: 31px;
    background-color: #d0e4ff;
    justify-content: space-between; /* 가로로 6등분 */
    display: flex;
    align-items: center;
}


.time-line-box span {
    flex: 1;
    text-align: left; /* 텍스트 가운데 정렬 */
    font-size: 14px;
    font-weight: 400;
}

.load-week-container {
    margin-left : 40px;
    display: flex;
    align-items: center;
    height: 100%;
    padding-right: 37px;
}

.load-text {
    font-size: 40px;
    font-weight: 700;
    margin-right: 20px;
    writing-mode: vertical-rl; /* 텍스트를 세로로 표시 */
    text-orientation: upright; /* 글자가 똑바로 보이게 */
}

.arrow-img {
    height: 100%; /* 이미지를 박스의 세로 기준 중앙에 맞추기 */
    transition: transform 0.2s ease-in-out;
}

.arrow-img.rotate {
  transform: rotate(180deg);
}