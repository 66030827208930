.nav_moment {
  position: absolute;
  bottom: 61px;
  width: 100%;
  background-color: #ffffff;
  border-radius: 15px 15px 0 0;
  & h4 {
    font-weight: 500;
    margin: 0;
    padding: 11px 24px;
  }
  .nav_form {
    padding-inline: 24px;

    .input-container {
      display: flex;
      margin-top: 8px;
      align-items: center;
      position: relative;
      height: 40px;

      .input-wrapper {
        width: 95%;
        /* background-color: blue; */
        display: flex;
        flex-direction: column;
        flex: 1;
        position: relative;

        .input-hashtag {
          background-color: white;
          color: #898989;
          /* width: 100%; */
          height: 70%;
          margin-inline: 5px;
          padding: 5px;
          border: none;
          border-bottom: 1px solid gray;

          &.input-hashtag:focus {
            outline: none;
          }
        }

        .count-text {
          color: #5e5e5e;
          font-size: 15px;
          display: flex;
          justify-content: end;
          margin-right: 5px;
          margin-top: 5px;
          position: absolute;
          bottom: -20px;
          right: 0;
        }
      }

      .button-wrapper {
        color: #797979;

        .check-button {
          all: unset;
          width: 30px;
          font-size: 13px;
        }
      }
    }

    .tag-container {
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      .tag-icon-box {
        margin-top: 5px;
        width: 25px;
        height: 25px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .tag-box {
        background-color: #4586e8;
        color: white;
        padding: 2px 5px;
        margin-left: 10px;
        /* margin-right: 5px; */
        margin-top: 5px;
        display: flex;
        align-items: center;
        height: 20px;
        font-size: 15px;
        border-top-left-radius: 10px;
        .delete-tag {
          background: none;
          border: none;
          color: black;
          cursor: pointer;
          margin-left: 5px;
          font-size: 1.2em;
        }
      }
    }

    .content-container {
      height: 150px;
      background-color: white;
      display: flex;
      flex-direction: column;
      /* padding: 20px; */

      .content-title {
        padding: 10px;
        border-bottom: 1px solid #cccccc;
        font-size: 13px;

        /* padding-bottom: 5px; */
      }

      .content-title-long {
        /* margin-bottom: 10px; */
        padding: 10px 20px;
      }

      .write_body {
        all: unset;
        /* background-color: blue; */
        border: none;
        padding: 10px;
        /* padding-top: 10px; */
        /* margin-left: 20px; */
        /* width: 100%; */
        flex: 1;
        word-wrap: break-word;
        overflow-wrap: break-word;
        white-space: break-spaces;
        /* height: 100%; */
        border-radius: 10px;
        resize: none;
        color: black;
        &::placeholder {
          font-size: 13px;
        }
      }
    }

    .select-container {
      display: flex;
      background-color: white;
      align-items: center;
      margin-bottom: 10px;

      .section_title {
        margin-bottom: 5px;
        margin-right: auto;
      }

      & section {
        width: 60%;
      }
    }

    .moment_footer {
      display: flex;
      align-items: center;
      .content_button {
        background-color: white;
        position: sticky;
        /* z-index: 999; */
        display: flex;
        justify-content: start;
        margin-right: auto;
        width: 100%;
        bottom: 0px;
        left: 0px;
        gap: 20px;
        font-size: 10px;

        & button {
          all: unset;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          padding: 5px 0px;
          cursor: pointer;

          img {
            width: 40px;
            height: 40px;
            object-fit: cover;
          }
        }

        button:first-child {
          margin-left: 20px;
        }
      }
      & p {
        width: 15%;
        font-size: 13px;
        color: #797979;
        cursor: pointer;

        &:last-child {
          color: #4586e8;
        }
      }
    }
  }
}

.success-message {
  position: fixed;
  top: 300px;
  left: 42%;
  z-index: 10;
  background: white;
  padding: 10px;
  border-radius: 5px;
}
