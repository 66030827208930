.wrapper-container {
  /* width: 100%; */
  height: 100vh;
  position: fixed;
  display: flex;
  align-items: end;
  justify-content: center;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background-color: rgba(0, 0, 0, 0.5);
  /* background-color: blue; */
  z-index: 1000;
}

.FilterModal {
  max-width: 500px;
  width: 100%;
  /* height: 421px; */
  margin: 0 auto;
  background-color: white;
  position: relative;
}

.FilterModal_title {
  /* height: 100px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 28px;
  h3 {
    font-size: 16px;
    /* font-weight: 500; */
  }

  p {
    all: unset;
    font-size: 13px;
    font-weight: 300;
  }
}

.FilterModal_kind {
  padding: 28px;
  font-size: 14px;

  h5 {
    font-size: 14px;
    font-weight: 600;

    span {
      color: gray;
      font-size: 10px;
      font-weight: 200;
    }
  }
  .button_container {
    /* margin-left: 28px; */
    button {
      margin-top: 8px;
      border: 1px solid #e4e9ee;
      cursor: pointer;
      min-width: 100px;
      padding: 8px 25px;
      margin-right: 17px;
      border-radius: 100px;
      font-size: 11px;
    }

    button:hover {
      background-color: plum;
    }

    .clicked_button {
      background-color: #000;
      color: white;
    }
  }
}

.FilterModal_buttons {
  padding: 20px;
  /* width: 100%; */
  display: flex;
  justify-content: center;

  button {
    /* padding: 10px; */
    cursor: pointer;
    height: 38px;
    font-size: 14px;
    font-weight: 600;
    border-radius: 3px;
  }

  .close_button {
    margin-right: 12px;
    width: 140px;
    border: 1px solid #e4e9ee;
    /* width: 30%; */
  }

  .apply_button {
    width: 294px;
    color: #1a80e6;
    border: 2px solid #359aff;
    background-color: #f1f7ff;
    /* width: 70%; */
  }
}

.reset-button {
  position: absolute;
  right: 13px;
  top: 12px;
  font-size: 10px;
  font-weight: 600;
  display: flex;
  align-items: center;
  color: #1a80e6;
  cursor: pointer;

  .reset-icon {
    align-items: center;
    display: flex;
    margin-right: 3px;
    img {
      object-fit: cover;
    }
  }
}
