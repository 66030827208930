.SearchPage {
  margin: 0 auto;
  height: 100vh;
  max-width: 500px;
  background-color: white;
}

.top-bar {
  margin: 0 auto;
  margin-bottom: 23px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .back {
    width: 40px;
    height: 40px;
    margin-left: 13px;

    img {
      object-fit: contain;
      width: 100%;
      height: 100%;
    }
  }
  div {
    /* height: 100%; */
    margin: 0;
    /* height: 50px; */
  }
}

.search-category {
  width: 100%;
  /* border: 1px solid black; */
  border-bottom: 11px solid #f1f7ff;
  h3 {
    padding-left: 21px;
    font-size: 15px;
    margin-top: 8px;
  }

  div {
    /* background-color: gray; */
    margin: 0 auto;
    padding-left: 10px;
    /* width: 80%; */
  }
}
.search-tag-box {
  /* display: flex; */
}
.search-tag {
  /* all: unset; */
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 7px 20px;
  height: 30px;

  border-radius: 20px;
  background-color: #d4e7ff;
  border: 1px solid #b9d8ff;
  min-width: 75px;
}
.delete-tag {
  margin-left: 11px;
}

.search-box-wrapper {
  width: 90%;
  /* margin-left: 38px; */
  padding-bottom: 12px;
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  overflow: hidden;
  scrollbar-width: none;
}

.searched-tag {
  cursor: pointer;
  background-color: #ffffff;
  min-width: 85px;
}

.tag-list {
  cursor: pointer;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(5, 1fr);
  grid-auto-flow: column;
  grid-gap: 13px;
  padding-left: 30px;
  list-style: none;
  margin-top: 26px;

  li {
    margin-left: 14px;
    font-size: 15px;
    counter-increment: list;

    &::before {
      content: counter(list);
      margin-right: 15px;
    }

    &:nth-child(1)::before,
    &:nth-child(2)::before,
    &:nth-child(3)::before {
      color: #fd3d24;
    }

    &:nth-child(9)::before,
    &:nth-child(10)::before {
      color: #1085f4;
    }
  }
}

.tag-item {
  background-color: #f0f0f0;
  padding: 10px;
  text-align: center;
  border: 1px solid #ddd;
  border-radius: 5px;
}
