.keyword-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 90px;
  /* background-color: blue; */
}

.title-container {
  margin-left: 28px;
  /* padding: 0 28px; */
  font-size: 20px;
  color: #323232;
  margin-bottom: 18px;
}

.sub-title {
  color: #0076ee;
  font-weight: 600;
}

/* 태그 박스 */
.tags-container {
  width: 100%;
  display: flex;
  overflow-x: auto;
  scrollbar-width: none;
  /* background-color: burlywood; */
  /* gap: 15px; */
}
.tags-wrapper {
  /* width: 100%; */
  display: flex;
  justify-content: center;
  align-items: center;
  /* animation: tagScroll 3s linear infinite; */
}

.tags {
  font-size: 14px;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  min-width: 70px;
  padding: 8px 15px;
  border-radius: 100px;
  margin-right: 15px;
  background-color: white;
  border: #e4e9ee solid 1px;
  color: #7960ec;
  cursor: pointer;

  &:first-child {
    margin-left: 15px;
  }
}

.click-tag {
  background-color: #7960ec;
  color: white;
}
