.MyPage_Comment_Box {
  width: 100%;
  height: 100%;
  background-color: white;
  margin-top: 5px;

  .Feed_title {
    border-bottom: 1px solid #f5f5f5;
    display: flex;
    padding: 6px 21px;
    align-items: center;

    & img {
      width: 30px;
      margin-right: 10px;
    }
    & p {
      margin: 0;
      font-weight: bold;
    }
  }

  .comment_box {
    padding: 0 29px;

    & li {
      display: flex;
      align-items: start;

      .Comment_content {
        margin: 13px 0 20px 19px;
        flex: 1;
      }

      & span {
        color: #828282;
        font-size: 14px;
        margin-block: 50px 11px;
      }
    }
  }
}
