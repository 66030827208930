.ReportPage {
  background-color: #f1f7ff;
}

.ReportPage_title {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 25px;
  padding-bottom: 30px;
  position: relative;

  button {
    all: unset;
    position: absolute;
    left: 15px;
    cursor: pointer;
    font-size: 14px;
    color: #797979;
  }
}

.Report_notice {
  padding: 20px;
  background-color: #ffffff;
  margin-bottom: 10px;

  p {
    margin: 0;
    line-height: 1.5;
  }
}

.Report_image {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  padding: 10px 0px 0px 10px;

  .img_box {
    width: 50px;
    height: 50px;
    background-color: gray;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.file_button {
  cursor: pointer;
  padding: 5px 10px;
  font-size: 14px;
  border: black 1px solid;
  border-radius: 5px;
}

#image {
  display: none;
}

.Report_input {
  width: 100%;
  height: 300px;
  resize: none;
  padding: 1rem;
  box-sizing: border-box;
  background-color: #f1f7ff;
  border: none;

  &:focus {
    outline: none;
  }
}

.Report_button_container {
  padding: 0px 20px;
}
.Report_button {
  width: 100%;
  padding: 10px 0px;
  border-radius: 4px;
  background-color: #9ec8ff;
  border: none;
  margin-top: 30px;
}
