.MySchedulePage {
  background-color: #f1f7ff;
}

.MySchedule__section {
  width: 100%;
  margin-bottom: 10px;
  background-color: white;

  li {
    list-style: none;
  }
}

.section_header {
  padding-left: 25px;
  padding-top: 20px;

  h3 {
    margin: 0px;
    font-size: 20px;
    font-weight: 600;
  }

  p {
    font-size: 14px;
  }
}

.bias_wrapper {
  margin-top: 20px;
  padding-left: 0px;
}

.moresee_button {
  display: flex;
  justify-self: center;
  justify-content: center;
  background-color: #fff;
  border: 1px solid #a6a6a6;
  color: #797979;
  padding: 10px;
  border-radius: 20px;
  width: 80%;
}

.button_container {
  padding: 20px 0px;
}
