.banner {
  position: relative;
  background-color: #ccc;
  margin: 0 10px 30px 10px;
  overflow: hidden;
  border-radius: 10px;
}

.banner-images {
  display: flex;
  width: 100%;
  height: 100%;
}

.image-box {
  flex: 0 0 100%;
  width: 100vw;
  height: 150px;
  align-items: center;
  float: left;
  cursor: pointer;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
