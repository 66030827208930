.ScheduleSelect {
  display: flex;
  flex-direction: column;
  background-color: #fff;
}

.EventMoreContainer {
  position: fixed; /* 화면 고정 */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex; /* 중앙 정렬을 위해 Flexbox 사용 */
  justify-content: center; /* 가로 중앙 정렬 */
  align-items: center; /* 세로 중앙 정렬 */
  background-color: rgba(0, 0, 0, 0.5); /* 어두운 반투명 배경 */
  z-index: 1000; /* 다른 요소 위로 올리기 */
}

.PickerMoreContainer {
  position: fixed; /* 화면 고정 */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex; /* 중앙 정렬을 위해 Flexbox 사용 */
  justify-content: center; /* 가로 중앙 정렬 */
  align-items: center; /* 세로 중앙 정렬 */
  background-color: rgba(0, 0, 0, 0.5); /* 어두운 반투명 배경 */
  z-index: 1000; /* 다른 요소 위로 올리기 */
}

.date,
.time {
  display: flex;
  align-items: center;
  cursor: pointer;
}

#startDate,
#endDate,
#startTime,
#endTime {
  display: none;
}

.DateAndTimeInfo {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-inline: 20px;
  /* margin-bottom: 50px; */
}

.DatePicker,
.TimePicker {
  width: 90%; /* 화면 폭 기준 크기 */
  max-width: 400px; /* 최대 크기 설정 */
  background-color: #fff; /* 흰색 배경 */
  border-radius: 10px; /* 둥근 모서리 */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3); /* 그림자 추가 */
  padding: 20px; /* 내부 여백 */
  text-align: center; /* 내부 콘텐츠 정렬 */
}

.DatePicker__container {
  font-size: 14px;
  padding-right: 20px;
  padding-top: 8px;
  display: flex;
  align-items: center; /* 세로 정렬을 가운데로 */
  /* gap: 18px; YearInfo와 MonthInfo 사이 간격 조정 */
  padding-bottom: 12px;
  width: 100%;
  justify-content: space-around;
}
.TimePicker__container {
  font-size: 14px;
}
.TimePicker__wrapper {
  display: flex;
  flex-direction: column;
}

.SearchSection {
  background-color: white;
  padding: 23px 28px 20px 28px;

  & h3 {
    margin: 0;
    font-weight: 600;
    font-size: 20px;
  }

  & button {
    color: #585858;
    background-color: #e4e4e4;
    border: none;
    border-radius: 6px;
    padding: 6px 11px;
  }
}

.sectionTop {
  display: flex;
  justify-content: space-between;
}

.ok-button {
  cursor: pointer;
}

.arrow {
  transition: transform 0.3s ease; /* 애니메이션 효과 */
}
.arrow.open {
  transform: rotate(180deg); /* 열린 상태에서의 회전 */
}
.arrow.closed {
  transform: rotate(0deg); /* 닫힌 상태 */
}

.DatePicker {
  cursor: grab; /* 기본 커서 */
  pointer-events: "none";
}

.DatePicker:active {
  cursor: grabbing; /* 드래그 중 커서 */
  pointer-events: "none";
}

.overlay {
  pointer-events: none; /* 기본적으로 클릭이 안 되도록 설정 */
  z-index: 10; /* Picker를 덮는 투명한 div */
}
