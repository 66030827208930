/* body {
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f0f0f0; /* 배경색을 원하는 색으로 변경 가능 
} */

/* .font {
  font-family: "Noto Sans KR", sans-serif;
} */
hr {
  opacity: 0.2;
}
.container {
  max-width: 500px;
  /* 너비를 원하는 비율로 설정 */
  height: 100%;
  margin: 0 auto;
  /*max-width: 1200px; /* 최대 너비를 설정 */
  background-color: #f1f7ff; /* 배경색을 원하는 색으로 변경 가능 */
  /*text-align: center; /* 텍스트 가운데 정렬 */
}
.TopBar {
  width: 100%; /* 너비를 원하는 비율로 설정 */
  height: 60px;
  margin: 0 auto;
  /*max-width: 1200px; /* 최대 너비를 설정 */
  background-color: #ffffff; /* 배경색을 원하는 색으로 변경 가능 */
  border-bottom: 2px solid #ffffff;
  /* text-align: center; 텍스트 가운데 정렬 */
  /* padding-top: 10px; */
  /* padding-bottom: 10px; */
  display: flex;
  align-items: center;
  justify-content: space-between; /* 좌우 공간을 동일하게 분배 */
  padding: 5px 15px;
  box-sizing: border-box;
}
.backword {
  width: 35px;
  height: 35px;
  filter: invert();
  cursor: pointer;
}

.TitleBox {
  color: black;
  font-weight: 600;
  text-align: center;
  flex: 1;
}
.EmptyBox {
  width: 35px;
}
/* .TitleName{
    color: white;
    /* text-align: center; /* 텍스트 가운데 정렬 
} */

.titleName {
  color: black;
  text-align: center;
  font-weight: 600;
}

.content {
  width: 100%;
  height: 950px;
  margin: 0 auto;
  background-color: #ffffff;
  /*text-align: center; /* 텍스트 가운데 정렬 */
  padding-top: 10px;
  /* box-sizing: border-box; */
}

.fullWidthComponent {
  width: calc(100% - 40px);
  height: 134px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 5px 20px;
  flex-direction: column;
  cursor: pointer;
  .nova_text {
    margin: 0;
    margin-top: 5px;
    margin-bottom: -5px;
    font-size: 13px;
  }
}
.list-bar {
  background-color: #f1f7ff;
  font-size: 16px;
  color: #626262;
  padding: 10px;
  padding-left: 20px;
  margin-bottom: 15px;
}
.listContainer {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.mainComponent:hover {
  background-color: #F1F7FF;
}
.mainComponent {
  width: 100%;
  height: 50px;
  background-color: white;
  display: flex;
  cursor: pointer;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid rgba(228, 228, 228, 0.2);
  transition: background-color 0.1s ease-in-out;
  .more_vector {
    width: 9px;
    height: 14px;
    margin-left: auto;
    margin-right: 40px;
  }
}


.bodyText {
  color: rgb(0, 0, 0);
  font-size: 13px;
  text-align: center; /* 텍스트 가운데 정렬 */
  font-weight: 400;
  /*margin-right: 23px; /* Add spacing between the text and icon */
}

.bodyText_login:hover {
  background-color: #F1F7FF;
}

.bodyText_login {
  transition: background-color 0.1s ease-in-out;
  color: rgb(0, 0, 0);
  font-size: 12px;
  text-align: center; /* 텍스트 가운데 정렬 */
  font-weight: 530;
  border: 1.5px solid #107bf4;
  border-radius: 5px;
  padding: 5px 30px;
  box-sizing: border-box;
  max-width: 200px;
  cursor: pointer;
}
.row {
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
}
.vector {
  width: 24px;
  height: 24px;
  text-align: center;
  margin-top: 5px;
  /*margin-right: 10px;*/
  margin: 30px;
}

.vector_login {
  width: 65px;
  height: 65px;
  margin: 0;
}

.inquiry {
  width: 100%; /* 너비를 원하는 비율로 설정 */
  height: 100px;
  margin: 0 auto;
  background-color: #ffffff; /* 배경색을 원하는 색으로 변경 가능 */
  display: flex;
  justify-content: center;
  align-items: end;
  flex-direction: row; /* Align icons horizontally */
  gap: 70px; /* Space between the icons */
  padding-bottom: 20px;
}

.iconBox {
  width: 50px;
  height: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.icon_img {
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.icon_name {
  color: rgb(0, 0, 0);
  text-align: center;
  margin-top: 5px; /* Space between the icon and the name */
  font-size: 11px;
  font-weight: 600;
}

.nova_project_text {
  font-size: 20px;
  font-weight: 700;
}
.nova_project_container {
  display: flex; /* 플렉스 컨테이너 설정 */
  align-items: center; /* 세로 중앙 정렬 */
}

.service-container {
  border: 1px solid #f2f2f2;
  margin-inline: 21px;
  padding: 14px;

  & h3 {
    margin: 0;
    font-weight: 300;
    font-size: 15px;
    color: #626262;
  }

  .button-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    margin-top: 21px;

    & button {
      border: 0;
      background-color: transparent;
      padding: 0;
      display: flex;
      align-items: center;
      color: #1c1c1c;
      font-size: 13px;
      cursor: pointer;

      & img {
        width: 24px;
        height: 24px;
        margin-inline: 11px 24px;
      }
    }
  }
}

/* 더보기만 반대로 다크 모드로 설정 */
:root {
  --bg-dark-color: #1e1e1e;
  --font-dark-color: #ffffff;
  --bar-dark-color: #0d0d0d;
}
.container.dark-mode {
  .TopBar {
    background-color: var(--bg-dark-color);
    border-color: var(--bg-dark-color);
  }
  .content {
    background-color: var(--bg-dark-color);
  }
  .bodyText_login {
    color: var(--font-dark-color);
    background-color: #000000;
  }
  .fullWidthComponent {
    background-color: var(--bg-dark-color);
  }
  .titleName {
    color: var(--font-dark-color);
  }
  .vector_login {
    filter: invert();
  }
  .list-bar {
    background-color: var(--bar-dark-color);
  }
  .mainComponent {
    background-color: var(--bg-dark-color);
  }
  .vector {
    filter: invert();
  }
  .bodyText {
    color: var(--font-dark-color);
  }
  .inquiry {
    background-color: var(--bg-dark-color);
  }
  .icon_name {
    color: var(--font-dark-color);
  }
  .footer {
    background-color: #090909;
  }
  .logo_img {
    filter: invert(0.5);
  }
  .nova_info {
    color: #6c6c6c;
  }
}
