h2 {
  padding: 20px;
  margin: 0;
}

.CategoryModal {
  /* width: 90%; */
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1001;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-container {
  width: 80%;
  height: 100%;
  background-color: white;
}

/* 게시판 */
.Board {
  width: 100%;
  /* background-color: gray; */
}

.Board_title {
  /* background-color: aqua; */
  margin: 0 auto;
  padding: 10px;
  width: 85%;
}

.LinkBox_container {
  display: flex;
  gap: 10px;
}

.LinkBox {
  width: 70px;
  height: 70px;

  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  border-radius: 5px;
}

.LinkBox_img {
  margin-top: 3px;
  width: 50px;
  height: 50px;
  /* background-color: blue; */
}
