.container {
  max-width: 500px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: flex-start; */
  /* 중간이 아닌 위쪽으로 배치 */
  height: 100vh;
  background-color: #fff;
  padding-top: 20px;
  /* 위쪽에 약간의 여백 추가 */
}

.Topbar {
  width: 100%;
  height: 60px;
  margin: 0 auto;
  background-color: #ffffff;
  border-bottom: 2px solid #ffffff;
  display: flex;
  align-items: center; /* 수직 중앙 정렬 */
  /* justify-content: flex-start; 왼쪽부터 정렬 */
  padding: 5px 15px;
  box-sizing: border-box;
  gap: 10px;
  position: relative;
}

.backword {
  width: 35px;
  height: 35px;
  filter: invert();
  cursor: pointer;
  position: absolute;
  top: 10px;
  left: 20px;
}

.backButton {
  font-size: 18px;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.title {
  flex-grow: 1;
  text-align: center;
  margin: 0;
  font-size: 20px;
}

.form {
  width: 100%;
  /* max-width: 400px; */
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

/* .label {
  padding-left: 10px;
  padding-bottom: 10px;
  font-size: 16px;
  color: #555;
  background-color: pink;
} */

.input {
  width: 100%;
  padding: 12px;
  margin-top: 10px;
  margin-bottom: 24px;
  border-radius: 8px;
  border: 1px solid #ccc;
  background-color: #f4f4f4;
  font-size: 16px;
}

.loginButton {
  /* justify-content: center; */
  /* 중간이 아닌 위쪽으로 배치 */
  width: 80%;
  padding: 15px;
  border-radius: 8px;
  background-color: #107bf4;
  color: #fff;
  font-size: 18px;
  cursor: pointer;
  border: none;
}

.sign-up {
  margin-top: 10px;
  text-align: center;
  text-decoration-line: underline;
  cursor: pointer;
}

/*  */

.input-box {
  margin: auto;
  width: 85%;
  /* background-color: blue; */
  margin-bottom: 15px;
  position: relative;

  .toggle-btn {
    background-color: transparent;
    border: 0;
    position: absolute;
    bottom: 30px;
    right: 30px;

    & img {
      width: 25px;
    }
  }
}

.input-box input {
  background-color: #f6f6f6;
  border-radius: 8px;
  margin-top: 10px;
  height: 45px;
  width: 90%;
  border: 1px solid #f6f6f6;
  padding-left: 15px;
  outline: none;
  margin-bottom: 10px;
}

.input-box input:focus {
  border: 1px solid #107bf4;
}

.input-box input[type="email"] {
  width: 90%;
}

.box-margin {
  margin-top: 20px;
}

.login-box {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  /* background-color: blue; */
}

.sign-up-btn {
  background-color: gray;
}

.errorMessage {
  color: red;
  font-size: 14px;
  /* margin-top: 5px; */
}

/*로그인페이지도 반대로  다크 모드 설정 */
:root {
  --bg-dark-color: #1e1e1e;
  --font-dark-color: #ffffff;
}
.container.dark-mode {
  background-color: var(--bg-dark-color);
  .Topbar {
    background-color: var(--bg-dark-color);
    color: var(--font-dark-color);
    border-color: var(--bg-dark-color);
  }
  .input-box {
    color: var(--font-dark-color);
  }
}
