.nav-back {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10000;
}

.bottom_bar {
  height: 60px;
  position: fixed;
  z-index: 10000;

  bottom: 0px;
  left: 0;
  right: 0;
  max-width: 500px;
  margin: 0 auto;
  background-color: #101010;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-bottom: calc(env(safe-area-inset-bottom) + 1px);
  /* 안전 영역 계산 */
  /* overflow: hidden; */
}

.write_select {
  color: #333333;
  font-size: 12px;
  position: absolute;
  background-color: #f1f7ff;
  width: 100%;
  bottom: 61px;
  display: flex;
  flex-flow: column;
  /* border-radius: 5px; */

  & h4 {
    font-weight: 500;
    padding-inline: 24px;
    font-size: 13px;
  }

  & section {
    display: inline-flex;
    justify-content: center;

    & button {
      font-weight: 200;
      background-color: transparent;
      border: 0;
      width: 100%;
    }

    &:nth-of-type(2) {
      border-top: 1px solid #dbe1e9;
      padding-top: 12px;

      gap: 16px;
      & button {
        background-color: #dbe1e9;
        width: 45%;
        padding: 9px 0;
        border-radius: 5px;
      }
    }
  }
}

.nav_button_box:hover {
  background-color: #bfdbff;
}

.nav_button_box {
  transition: background-color 0.15s ease-in-out;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  margin: 0px 0px 0px 0px;
}

.nav_button {
  width: 100%;
  height: 85%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  /* 배경이 필요 없으면 투명하게 설정 */
  border: none;
  cursor: pointer;
  /* 버튼 테두리 없애기 */
}

.btn_clicked {
  background-color: #bfdbff;
  border-radius: 6px;
}

.btn_wrapper {
  width: 60%;
  height: 60%;
  background-color: #bfdbff;
}

.btn_img {
  width: 70%;
  /* 이미지가 버튼 크기에 맞춰지도록 */
  height: auto;
  max-width: 40px;
  max-height: 40px;
}

.btn_text {
  font-variation-settings: "wght" 400;
  margin-block: 5px;
  /* 아이콘과 텍스트 사이 간격 */
  font-size: 0.7em;
  color: #666;
  white-space: nowrap;
  /* 텍스트가 한 줄로 유지되도록 */
  text-align: center;
  width: 100%;
  display: flex;
  justify-content: center;
  /* 텍스트가 들어갈 공간 확보 */
}

.button_container {
  & button {
    font-variation-settings: "wght" 300;
  }
  margin: 12px 0px;
}
