
.searchFac {
  margin-block: 10px 12px;
  background-color: #fff;
  padding-left : 28px;
  padding-right: 28px;

  .searchBox {
    border-bottom: 1px solid black;
    width: 100%;
    display: flex;
    justify-self: center;
    padding-block: 8px;

    input {
      margin-right: auto;
      width: 100%;
      border: none;
      background: none;
      padding-top: 12px;
      padding-left: 12px;
      padding-right: 12px;
      margin: 0;

      &::placeholder {
        color: #797979;
        font-size: 14px;
        font-weight: 600;
      }

      &:focus {
        border: none;
        outline: none;
      }
    }
    img {
      width: 18px;
    }
  }
}

.wordSection {
  display: flex;
  gap: 8px;
  & img {
    background-color: #d0e4ff;
    padding: 6px 10px;
    border-radius: 6px;
  }

  & button {
    background-color: #d0e4ff;
    &:hover {
      filter: brightness(0.9);
    }
  }
}

.top-delete-box {
  width: 100%;
  display: flex;
  justify-content: flex-end; /* Flexbox로 오른쪽 정렬 */
}

.remove-schedule-button {
  margin: 0; /* 부모에서 상속된 마진을 무시 */
  padding-bottom: 2px;
  border-bottom: 2px solid #5C5C5C; /* hover 시 밑줄 색상 변경 */
  width: 55px;
  cursor: pointer;
  text-align: center; /* 텍스트를 가운데 정렬 */
}

.remove-schedule-text {
  margin: 0; /* 부모에서 상속된 마진을 무시 */
  font-size: 14px;
  color: #5C5C5C;
}