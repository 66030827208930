/* .carousel {
    display: flex;
    align-items: center;
  }
  
  .carousel-content {
    width: 300px; 
    height: 200px; 
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #ccc;
    margin: 0 10px;
  }
   */

.slider-container {
  height: 100%;
  width: 100%;
  /* 부모 요소의 너비 설정 */
  position: relative;
  /* 자식 요소 배치에 필요한 상대적 위치 설정 */
  /* background-color: blueviolet; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.slider-items {
  position: relative;
  height: 85%;
  /* background-color: greenyellow; */
  width: 100%;
  color: white;
}

.slick-slide {
  position: relative;
  /* padding-left: 10px; */
  height: 100%;
  /* background-color: antiquewhite; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.slide-content {
  margin: 0 auto;
  position: relative;
  width: 85%;
  /* height: 250px; */
  /* 부모 높이에 맞게 설정 */
  background-color: #101010;
  color: white;
  border-radius: 15px;
  padding: 22px;
  cursor: pointer;
  /* 텍스트 색상 */
  display: flex;
  flex-direction: column;
  border: 1px solid #e9e9e9;
  /* Flexbox를 사용하여 내용 정렬 */
  /* align-items: center; */
  /* 수직 중앙 정렬 */
  /* justify-content: center; */
  /* 수평 중앙 정렬 */
}

.slide-box {
  /* height: 300px; */
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: yellow; */
}

/* 점 컨테이너 */
.slick-dots {
  /* color: white; */
  bottom: 10px; /* 점의 위치 조정 */
}

/* 각 점 */
.slick-dots li {
  /* color: white; */
  background-color: #1e1e1e;
  margin: 0 5px; /* 점 간격 조정 */
}

/* 활성 점 */
.slick-dots li.slick-active button {
  /* background-color: black; 활성 점 색상 */
  background-color: #1e1e1e;
  border: 0;
}

/* 비활성 점 */
.slick-dots li button {
  /* background-color: #ccc; 비활성 점 색상 */
  /* opacity: 0.6; */
  background-color: #1e1e1e;
}

/* 점 버튼 스타일 */
.slick-dots button {
  border: none; /* 테두리 제거 */
  width: 12px; /* 점 크기 조정 */
  height: 12px; /* 점 크기 조정 */
  cursor: pointer; /* 포인터 커서 */
  background-color: #1e1e1e;
}

/* 여기가 색 변경되는 부분 */
.slick-dots li button::before {
  color: #4194f7 !important;
  /* background-color: #1e1e1e; */
  background-color: #1e1e1e;
  border: none;
}
.slick-dots li button::after {
  background-color: #1e1e1e;
}
/* 라이트 모드 */
.slide-content.bright-mode {
  background-color: #ffffff;
  color: #232323;
  border: 1px solid #e4e9ee;
  height: 433px;
}
.bright-mode {
  .slick-dots li {
    background-color: #ffffff;
  }
  .slick-dots li button::before {
    background-color: #ffffff;
  }

  .slick-dots li.slick-active button {
    background-color: #ffffff;
  }
  .slick-dots li button {
    background-color: #ffffff;
  }
}
/*  */
/*피드만 높이가 짧게 */

.slider-container.custom-height {
  .slide-content {
    height: 240px;
  }
  .slick-dots {
    background-color: #f1f7ff;
    padding-block: 8px;
    bottom: -41px;
  }

  .slick-dots li button::before {
    background-color: #f1f7ff;
  }

  .slick-dots li.slick-active button {
    background-color: #f1f7ff;
  }
  .slick-dots li button {
    background-color: #f1f7ff;
  }
}
