.story_feed {
  display: flex;
  flex-direction: column;
  width: 215px;
  height: 245px;
  border-radius: 11px;
  background-color: #fffefe;
  border: 1px solid #e0e0e0;
  color: #1f1f1f;
  cursor: pointer;
}

@media (max-width: 475px) {
  .story_feed_home {
    min-width: 100%;
    height: 100%;

    .all-img {
      margin: 0 auto;
      width: 97%;
    }
  }
}

.all-img {
  margin: 0 auto;
  width: 206px;
  height: 189px;

  img {
    border-radius: 9px;
    margin-top: 4px;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.all-text {
  display: flex;
  align-items: center;
  flex: 1;
  margin-left: 10px;
  p {
    width: 90%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    /* word-break: break-word; */
  }
}
