.container {
  max-width: 500px;
  overflow: hidden;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100vh;
  background-color: #1e1e1e;
  border: 1px solid white;

  .header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding-top: 10px;

    padding-bottom: 10px;
    background-color: #000000;
    img {
      filter: invert();
    }
  }

  .fav-title {
    font-size: 18px;
    margin-block: 6px 0;
    color: #107bf4;
  }
  h3 {
    margin-block: 25px 27px;
    font-size: 18px;
    b {
      color: #107bf4;
    }
  }

  .following {
    text-align: center;
    font-size: 18px;
    background-color: #fff;
    width: 100%;
    h4 {
      margin-block: 7px 17px;

      b {
        color: #107bf4;
      }
    }
  }

  .search-fac {
    margin-block: 12px;
    background-color: #fff;
    width: 100%;
    padding-block: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .search-box {
      border-bottom: 1px solid black;
      margin: 0px 25px;
      display: flex;
      padding-block: 6px;

      input {
        margin-right: auto;
        width: 100%;
        border: none;
        background: none;
        padding: 0;
        margin: 0;

        &::placeholder {
          color: #6aacef;
          font-weight: 600;
        }

        &:focus {
          border: none;
          outline: none;
        }
      }
      img {
        width: 18px;
      }
    }

    .no_result {
      margin: 0px 25px;
      color: #c6c6c6;
      padding-block: 60px;
      align-self: self;
      justify-self: center;
    }

    .fav-apply {
      cursor: pointer;
      display: flex;
      align-items: center;
      margin: 0px 25px;
      margin-top: 32px;
      background-color: white;
      border-radius: 5px;
      border: 1px dotted #727272;
      position: relative;
      width: 90%;
      background-color: white;
      padding-block: 5px;
      img {
        position: absolute;
        left: 5px;
        width: 30px;
      }

      span {
        margin: 0 auto;
        text-align: center;
        p {
          margin: 0;
          font-size: 13px;
        }
        b {
          font-size: 15px;
        }
      }
    }
  }

  .streamer-box {
    background-color: #fff;
    width: 100%;
    padding-block: 20px;
    display: flex;
    flex-flow: column;
    justify-content: center;

    h4 {
      text-align: center;
      font-size: 18px;
      margin: 0;
      margin-bottom: 15px;
    }

    .streamer-list {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      margin: 10px 25px;
      /* display: flex;
      gap: 15px;
      margin: 10px 10px;
      flex-wrap: wrap;*/
      justify-items: center;
    }

    @media (max-width: 400px) {
      .streamer-list {
        grid-template-columns: repeat(2, 1fr);
      }
      .streamer-list button {
        width: 45%;
      }
    }

    @media (max-width: 300px) {
      .streamer-list {
        grid-template-columns: repeat(1, 1fr);
      }
      .streamer-list button {
        width: 100%;
      }
    }

    .more-see {
      margin-inline: 34px;
      padding-block: 13px;
      border-color: #a6a6a6;
      border-radius: 16px;
      margin-top: 40px;
      background-color: white;
      /* border-radius: 5px; */
      /* border: 1px solid #eee9fc; */
    }
  }
}

/* 스트리머 이미지지 */
.streamer-img {
  border: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 120px;
  padding: 10px;
  margin-inline: 10px;
  background-color: white;
  border-radius: 5px;
  cursor: pointer;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
  }

  div {
    background-color: gray;
    width: 120px;
    height: 120px;
    border-radius: 50%;
  }

  p {
    margin-block: 8px;
    font-size: 15px;
  }
}
/* 모달창 */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* 어두운 배경 */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* 모달이 다른 요소들 위에 표시되도록 설정 */

  .modal {
    background-color: #f1f7ff;
    padding: 20px;
    border-radius: 8px;
    width: 300px;
    text-align: center;
    z-index: 1001; /* 모달 내용이 배경보다 위에 오도록 */

    .streamer-img {
      background-color: transparent;
      display: flex;
      justify-self: center;
    }

    p {
      b {
        color: #107bf4;
      }
    }

    span {
      display: flex;
      justify-content: space-evenly;
      gap: 10px;

      button {
        width: 100%;
        margin-top: 15px;
        padding: 10px;
        background-color: white;
        color: black;
        font-weight: 600;
        border: none;
        border-radius: 5px;
        cursor: pointer;
      }

      .follow-button {
        background-color: #d4e7ff;
      }
    }
  }
}

/* 라이트 모드 */
.container.bright-mode {
  background-color: #f1f7ff;
  .header {
    background-color: #f1f7ff;
    img {
      filter: none;
    }
  }
}

button {
  /* align-self: flex-end; */
  /* padding: 5px 15px; */
  /* background-color: white;
  border-radius: 5px;
  border: 1px solid #eee9fc; */
}
