.wrap-container {
  background-color: #1e1e1e;
  width: 100%;
  height: 500px;
  display: flex;
  flex-direction: column;
  color: white;
}

.allpost-container {
  height: auto;
}

.top-area {
  flex-grow: 1;
  background-color: #1e1e1e;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.content-title {
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* background-color: gold; */
  flex-grow: 2;
  width: 95%;
}

.header-text {
  padding: 20px;
  font-size: 20px;
  font-weight: 600;
}

.more-icon {
  width: 13px;
  transform: scaleX(-1);
  margin-right: 20px;
  cursor: pointer;
}

.tag-container {
  display: flex;
  /* flex-grow: 1; */
  /* gap: 3px; */
  overflow-x: auto;
  white-space: nowrap;
  -ms-overflow-style: none;
  scrollbar-width: none;
  /* width: 500px; */
  /* overflow: auto; */
  margin-bottom: 10px;
}

.tag-container::-webkit-scrollbar {
  display: none;
}

.hashtag-text {
  background-color: #373737;
  color: #ffffff;
  border: 0;
  border-radius: 20px;
  padding: 8px 35px;
  min-width: 80px;
  flex-shrink: 0;
  margin-left: 15px;
}

.main-area {
  flex-grow: 5;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.feed-box {
  width: 75%;
  height: 70%;
  max-width: 600px;
  background-color: black;
  border-radius: 10px;
  padding: 20px;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
}

.name-container {
  display: flex;
  align-items: center;
  height: 30px;
  margin-left: 7px;
}

/* .profile {
} */

.name-text {
  font-size: 15px;
  margin-right: auto;
}

.more-see {
  border: 0;
  background-color: black;
  color: #737373;
  cursor: pointer;
}

.text-container {
  justify-content: start;
  margin-top: 38px;
  margin-left: 7px;
  margin-bottom: auto;
}

.tag-text {
  display: flex;
  gap: 5px;

  .tag {
    font-variation-settings: "wght" 200;
    color: #3621d0;
    font-size: 14px;
    font-weight: 100;
  }
}

.main-text {
  font-size: 14px;
  margin-top: 10px;
  overflow-wrap: break-word;
  font-weight: 400;
}

.like-comment {
  color: #8b8b8b;
  justify-items: flex-end;
  align-self: flex-end;
  font-size: 13px;
  margin-top: auto;
}

/* ------------------ */
/* 인기 게시글 */
.popular-feed-container {
  overflow-x: auto;
  scrollbar-width: none;
  justify-content: start;
}

.popular-feed {
  width: 75%;
  height: 90%;
  border-radius: 10px;
  flex-shrink: 0;
  margin-left: 20px;
  cursor: pointer;

  padding: 10px;
  background-color: #212327;
}

.img-box {
  width: 90%;
  height: 60%;
  margin: 0 auto;
  margin-top: 10px;
  color: black;
  border-radius: 5px;
}

.img-box > img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 5px;
}

.popular-main {
  margin: 15px;
}

.popular-text {
  font-size: 15px;
  color: #ffffff;
  margin-top: 5px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 20px;
  height: 50px;
}

/* ----------------- */
/* 급상승 해시태그 */
.hashtag-box {
  width: 100%;
  height: 100%;
  background-color: #212327;
  border-radius: 10px;
  display: flex;
  flex-flow: column;
  padding-bottom: 10px;
}

.time-text {
  margin-left: auto;
  margin-right: 10px;
  margin-top: 10px;
  color: #cacaca;
}

.ranking-container {
  list-style: none;
  padding: 0;
  margin: 0;
}

.ranking-box {
  display: flex;
  margin: 20px;
  align-items: center;
}

.ranking {
  min-width: 40px;
  text-align: left;
}

.ranking-img {
  min-width: 40px;
}
.ranking-img > img {
  object-fit: contain;
  width: 25px;
  height: 25px;
}

.ranking-name {
  margin-left: 20px;
  counter-reset: #fafafa;
  align-self: flex-start;
  text-decoration: underline;
  cursor: pointer;
}

.feed-container {
  width: 75%;
  height: 70%;
  max-width: 600px;
  background-color: black;
  border-radius: 10px;
  /* padding: 20px; */
  display: flex;
  flex-flow: column;
  justify-content: space-between;
}

/* 전체 게시글 */
.all-main-area {
  flex-direction: column;
}
.all-list {
  width: 100%;
  padding: 0;

  li {
    /* margin-bottom: 5px; */
    display: flex;
    background-color: #fffefe;
    padding: 15px 8px;
    border: 1px solid #e0e0e0;
    color: #1f1f1f;
    cursor: pointer;
    margin-bottom: 3px;
    margin-inline: 20px;
  }
}

.all-img {
  width: 100px;
  height: 100px;
  background-color: gray;
}

.all-img > img {
  width: 100px;
  height: 100px;
  object-fit: cover;
}

.all-text {
  padding-left: 20px;
  width: 100%;
  span {
    color: #6c3bff;
    padding-right: 10px;
    height: 1em;
  }

  p {
    width: 90%;
    margin: 0;
    font-size: 18px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    word-break: break-word;
    margin-bottom: 30px;
  }
  .like-comment {
    margin-top: auto;
  }
}
.all-text_container {
  height: 80px;
}

.all_see-button {
  width: 85%;
  align-self: center;
  background-color: #ddd1f2;
  border: 2px solid #7e60b1;
  padding-block: 10px;
  border-radius: 20px;
  margin-bottom: 20px;
  color: black;
  cursor: pointer;
  text-shadow: 0 0 1px white;
}
@media (max-width: 1024px) {
  .all_see-button {
    margin-bottom: 70px;
  }
}
/* --------------- */
/* 라이트모드 스타일 */

.top-area.bright-mode {
  background-color: #f8f8f8;
  color: black;
}
.more-see.bright-mode {
  background-color: #f4f4f4;
}
.wrap-container.bright-mode {
  background-color: #f8f8f8;
}
/* 관련 인기 해시태그 */
.hashtag-text.bright-mode {
  background-color: #cccfff;
}

/* 최근 인기 게시글 */
.main-area.bright-mode {
  background-color: #f8f8f8;
}
.popular-text.bright-mode {
  color: #1f1f1f;
}
.popular-feed.bright-mode {
  background-color: #ffffff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3); /* 수평, 수직, 블러, 색상 */
}
/* 전체 글 */
.main-area.all-main-area.bright-mode {
  background-color: #f8f8f8;
}
.all-list.bright-mode {
  li {
    background-color: #f1f7ff;
  }
}
/* width 작을 때 급상승 해시태그 */
.hashtag-box.bright-mode {
  background-color: #f8f8f8;
  color: #1b1b1b;
}
/* 더보기 아이콘 */
.more-icon.bright-mode {
  filter: invert(1);
}

/* 피드 css 수정 버전 */
.img-container {
  margin: 0 auto;
  width: 100%;
  height: 300px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 15px;
  }
}
