.Select_box {
  margin: 0 auto;
  max-width: 445px;
  display: flex;
  /* flex: 1; */
  flex-direction: column;
  border: 1px solid #4586e8;
  border-radius: 6px;
  padding: 12px;
  position: relative;
  cursor: pointer;
}

.Select_options {
  display: none;
}

.Select_options_on {
  all: unset;
  display: flex;
  flex-direction: column;
  list-style: none;
  margin: 0 auto;
  max-width: 470px;
  margin-top: 5px;
  background-color: #fff;
  z-index: 50;
  border: 1px solid gray;
  border-radius: 6px;
  max-height: 60px;
  overflow-y: auto;

  li {
    padding: 0;
    padding: 5px;
  }
  li:hover {
    display: flex;
    background-color: gray;
  }

  li:hover:first-child {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
  li:hover:last-child {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
}
