.container {
  max-width: 500px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100vh;
  background-color: #fff;
  padding-top: 20px;
}

.Topbar {
  width: 100%;
  height: 60px;
  margin: 0 auto;
  background-color: #ffffff;
  border-bottom: 2px solid #ffffff;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 5px 15px;
  box-sizing: border-box;
  gap: 10px;
}

.backword {
  width: 35px;
  height: 35px;
  filter: invert();
  cursor: pointer;
}

.title {
  color: black;
  font-weight: 600;
  text-align: center;
  flex: 1;
}

.EmptyBox {
  width: 30px;
}

.form {
  display: flex;
  flex-direction: column;
  margin: 60px auto;
  width: 70%;
}

.section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.input_text {
  font-size: 15px;
}
/* .input_box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
} */
.input {
  display: block;
  margin-bottom: 10px;
  padding: 20px;
  width: 100%;
  border: 0;
  background-color: #f6f6f6;
  border-radius: 5px;
}

.button {
  display: block;
  width: 90%;
  padding: 10px;
  border: 0;
  color: white;
  background-color: #107bf4;
  border-radius: 8px;
  font-size: 15px;
  margin-top: 5px;
  cursor: pointer;
}

.error {
  color: red;
  justify-self: start;
}
