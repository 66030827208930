.SearchSection {
  background-color: white;
  padding: 0px 25px 20px 25px;

  & h3 {
    margin: 0;
    font-weight: 600;
    font-size: 20px;
  }

  & button {
    color: #585858;
    background-color: #e4e4e4;
    border: none;
    border-radius: 6px;
    padding: 6px 11px;
  }
}

.sectionTop {
  display: flex;
  justify-content: space-between;
}

.searchFac {
  margin-block: 33px 12px;
  background-color: #fff;
  width: 100%;

  .searchBox {
    border-bottom: 1px solid black;
    width: 100%;
    display: flex;
    justify-self: center;
    padding-block: 6px;

    input {
      margin-right: auto;
      width: 100%;
      border: none;
      background: none;
      padding: 0;
      margin: 0;

      &::placeholder {
        color: #6aacef;
        font-size: 14px;
        font-weight: 600;
      }

      &:focus {
        border: none;
        outline: none;
      }
    }
    img {
      width: 18px;
    }
  }
}

.wordSection {
  display: flex;
  gap: 8px;
  & img {
    background-color: #d0e4ff;
    padding: 6px 10px;
    border-radius: 6px;
  }

  & button {
    background-color: #d0e4ff;
    &:hover {
      filter: brightness(0.9);
    }
  }
}
.top-container {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  padding: 10px 0px;
  gap: 10px;
}

.back-button {
  all: unset;
  cursor: pointer;
  display: flex; /* 이 부분을 추가 */
  align-items: center; /* 이미지와 텍스트 세로 정렬 */
}
.back-button img {
  margin-right: 8px; /* 이미지와 텍스트 사이에 간격을 추가 */
}
