.EventMoreContainer {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 10001;
  background-color: transparent;
  opacity: 0;
  transform: translateY(100%);
  transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
  pointer-events: none;
}

.eventMain {
  background-color: #fff;
  width: 100%;
  height: 150vh;
  margin-top: 54px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  transition: transform 0.5s ease-in-out;
  transform: translateY(100%);

  & .topImage {
    display: flex;
    justify-content: center;
    padding-block: 13px 0;
  }
}

/* 올라가고 내려가는 애니메이션 */
.see {
  opacity: 1;
  transform: translateY(0);
  pointer-events: auto;
  transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
}
.on {
  transform: translateY(0);
}

/* 이벤트 자세히보기 캘린더 박스 */

.previewBox {
  display: flex;
  flex-flow: column;
  align-items: center;

  & h3 {
    font-weight: 600;
    font-size: 20px;
  }
}

.searchFac {
  margin-block: 10px 12px;
  background-color: #fff;
  padding-left: 28px;
  padding-right: 28px;

  .searchBox {
    border-bottom: 1px solid black;
    width: 100%;
    display: flex;
    justify-self: center;
    padding-block: 8px;

    input {
      margin-right: auto;
      width: 100%;
      border: none;
      background: none;
      padding-top: 12px;
      padding-left: 12px;
      padding-right: 12px;
      margin: 0;

      &::placeholder {
        color: #797979;
        font-size: 14px;
        font-weight: 600;
      }

      &:focus {
        border: none;
        outline: none;
      }
    }
    img {
      width: 18px;
    }
  }
}

.wordSection {
  display: flex;
  gap: 8px;
  & img {
    background-color: #d0e4ff;
    padding: 6px 10px;
    border-radius: 6px;
  }

  & button {
    background-color: #d0e4ff;
    &:hover {
      filter: brightness(0.9);
    }
  }
}

.moreContainer {
  background-color: #fff;
  display: flex;
  justify-content: end;
  gap: 10px;
  padding: 0 20px 20px;

  & button {
    border: none;
    width: 40%;
    padding-block: 7px;
    border-radius: 9px;
    color: #5c5c5c;
    font-size: 16px;

    &:last-child {
      background-color: #d0e4ff;
    }

    &:hover {
      filter: brightness(0.9);
    }
  }

  /*버튼 3개일 때 자세히 버튼 */
  & .moreButton {
    width: 15%;
  }
}

.additional-schedule-button {
  margin: 0; /* 부모에서 상속된 마진을 무시 */
  padding-top: 20px;
  padding-bottom: 22px;

  /* hover 시 밑줄 색상 변경 */
  width: 100%;
  cursor: pointer;
  background-color: #fff;
}

.additional-schedule-text {
  margin: 0; /* 부모에서 상속된 마진을 무시 */
  font-size: 16px;
  border-bottom: 2px solid #5c5c5c;
  margin-left: 34px;
  width: 60px;
  color: #5c5c5c;
}

.modal-title {
  width: 100%;
  margin-top: 30px;
  text-align: center;
  display: flex;
  justify-content: center; /* 수평 중앙 정렬 */
  align-items: center; /* 수직 중앙 정렬 */
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 20px;
}

.bias-container {
  margin-top: 10px;
}
